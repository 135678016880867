@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.NewLinesForm {
  padding: $spacing-05 0;
  overflow: auto;
  margin-bottom: $spacing-05;
  .cds--label {
    @include body-emphasis-02;
  }
  .cds--number__input-wrapper {
    max-width: none;
  }
  .NewLinesForm__performance,
  .NewLinesForm__lookalike {
    margin: 0;
  }
  .NewLinesForm__price-point {
    position: relative;
    max-width: 250px;
    #price-point {
      padding-left: $spacing-06;
    }
    &::after {
      content: '$';
      position: absolute;
      top: $spacing-04;
      left: $spacing-05;
    }
  }
  .NewLinesForm__margin-rate {
    position: relative;
    max-width: 250px;
    &::after {
      content: '%';
      position: absolute;
      top: $spacing-04;
      right: $spacing-05;
    }
  }
}
