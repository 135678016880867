@use '@carbon/styles/scss/utilities/custom-property' as theme;

// Font weights
$font-weight-01: 100; //Inter-Thin
$font-weight-02: 200; //Inter-ExtraLight
$font-weight-03: 300; //Inter-Light
$font-weight-04: 400; //Inter-Regular
$font-weight-05: 500; //Inter-Medium
$font-weight-06: 600; //Inter-SemiBold
$font-weight-07: 700; //Inter-Bold
$font-weight-08: 800; //Inter-ExtraBold
$font-weight-09: 900; //Inter-Black

// Font size
$font-size-01: 0.25rem; // 4px
$font-size-02: 0.5rem; // 8px
$font-size-03: 0.625rem; // 10px
$font-size-04: 0.75rem; // 12px
$font-size-05: 0.875rem; // 14px
$font-size-06: 1rem; // 16px
$font-size-07: 1.125rem; // 18px
$font-size-08: 1.25rem; // 20px
$font-size-09: 1.5rem; // 24px
$font-size-10: 1.75rem; // 28px
$font-size-11: 2rem; // 32px
$font-size-12: 2.25rem; // 36px
$font-size-13: 2.5rem; // 40px
$font-size-14: 2.625rem; // 42px
$font-size-15: 2.75rem; // 44px
$font-size-16: 3rem; // 48px
$font-size-17: 3.375rem; // 54px

// Border
$border-radius-01: 8px;
$border-radius-02: 10px;

// Legend items
$legend-dot-height: 12px;
$legend-dot-border-radius: 6px;

// Navbar
$nav-height: 50px;

// Sidebar
$sidebar-width: 64px;

//Box-shadow
$boxShadow-01: 0px 0px 5px rgba(0, 0, 0, 0.1);
$boxShadow-02: 0px 0px 5px theme.get-var('border-subtle');
$boxShadow-03: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
// Animations
$animated-ellipsis: animate-ellipsis steps(1, end) 1.75s infinite;

@keyframes animate-ellipsis {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}
