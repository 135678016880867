@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.FiltersPopover {
  .FiltersContent__target {
    width: 100px;
  }
  .FiltersContent__container {
    width: 350px;
    padding: $spacing-04;
    border-radius: $border-radius-01;
    display: flex;
    flex-direction: column;
    max-height: 65vh;

    .FiltersContent__header {
      display: flex;
      justify-content: space-between;
      padding: $spacing-05;
      align-items: center;
      .cds--btn--ghost {
        color: theme.get-var('secondary');
      }
    }
    .FiltersContent__title {
      display: flex;
      gap: $spacing-03;
      @include label-02;
    }
    .FiltersContent__filters-container {
      overflow-y: auto;
    }
    .FiltersContent__filter-heading {
      .cds--accordion__heading {
        @include body-emphasis-02;
        padding: 14px 16px;
        border-bottom: none;
        border-top: 1px solid theme.get-var('border-subtle');
      }
      .FiltersContent__filter-title {
        display: flex;
        align-items: center;
        gap: $spacing-05;
      }
      .FiltersContent__filters-checkbox {
        height: 34px;
        color: theme.get-var('text-secondary');
      }
      .cds--tag {
        margin: 0;
      }
    }
    .FiltersContent__options-container {
      max-height: 140px;
      overflow-y: auto;
    }
  }
}

.FiltersPopover__options-list {
  margin-top: 30px;
  display: flex;
  gap: $spacing-04;
  .FiltersPopover__option-title {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  .FiltersPopover__selected-options {
    display: flex;
    gap: $spacing-04;
    flex-wrap: wrap;
    .cds--tag {
      margin: 0;
      padding: 0 0 0 $spacing-03;
      background-color: theme.get-var('field-02');
      cursor: default;
      .cds--tag__close-icon {
        background-color: theme.get-var('background-hover-subtle');
        color: theme.get-var('text-helper');
        svg {
          display: block;
        }
        &:hover {
          background-color: theme.get-var('tag-hover-gray');
        }
      }
    }
  }
}
