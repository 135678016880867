@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/_variables';
@import '../abstracts/mixins';

.info-overlay {
  position: relative;
  height: 2rem;
  z-index: 2;
  width: 300px;
  .info-overlay__title {
    @include body-03;
    display: flex;
    align-items: center;
    gap: $spacing-03;
    cursor: pointer;
  }
  .info-overlay__content {
    @include body-03;
    background-color: theme.get-var('background');
    cursor: default;
    padding: $spacing-03;
    border-radius: $border-radius-01;
    position: absolute;
    border-left: 2px solid theme.get-var('border-interactive-primary');
    box-shadow: $boxShadow-01;
    .info-overlay__items--open {
      display: block;
      width: 100%;
    }
    .info-overlay__items--closed {
      display: none;
    }
    .info-overlay__item {
      padding: $spacing-03 0;
      &:first-child {
        margin-top: $spacing-04;
      }
      &:not(:last-child) {
        border-bottom: 1px solid theme.get-var('border-subtle');
      }
    }
  }
}
