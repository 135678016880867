@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/_variables';
@import '../abstracts/_mixins';

.ClusterStrategy {
  display: flex;
  align-items: center;
  border: 1px solid theme.get-var('border-strong');
  border-left: 3px solid theme.get-var('border-strong');
  border-radius: $border-radius-02;
  color: theme.get-var('text-secondary');
  padding: $spacing-03 $spacing-05;
  margin-right: $spacing-05;
  gap: $spacing-04;
  @include body-02;
}
