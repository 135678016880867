@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.notifications {
  &-popover {
    z-index: 0;
    .notifications-content {
      border-radius: $border-radius-01;
    }
  }
  &-content {
    margin-right: $spacing-03;
    margin-bottom: -$spacing-02;
    user-select: none;
    background-color: theme.get-var('background');
    .notifications-header {
      display: flex;
      box-shadow: $boxShadow-01;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid theme.get-var('border-subtle');
      padding: $spacing-05;
      .icon-title {
        align-self: flex-start;
      }
      svg {
        margin-right: $spacing-04;
        color: theme.get-var('icon-primary');
      }
      .notifications-title {
        align-self: flex-start;
        color: theme.get-var('text-primary');
        @include label-02;
      }
    }
    .notifications-list {
      max-height: 400px;
      width: 500px;
      overflow-y: auto;
    }
    .mark-as-read {
      cursor: pointer;
      @include body-03;
      padding: $spacing-03 0;
      color: theme.get-var('text-primary');
      text-align: right;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-icon {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: $spacing-03;
    &.has-unseen {
      &::after {
        content: '';
        position: absolute;
        top: 13px;
        right: 13px;
        height: 7px;
        width: 7px;
        border-radius: 4px;
        background-color: theme.get-var('icon-primary-bright');
      }
    }
  }
  &-item {
    display: grid;
    grid-template-columns: 46px 1fr 0.1fr;
    position: relative;
    padding: $spacing-05;
    margin: 0 $spacing-05;
    border-bottom: 1px solid theme.get-var('border-subtle');
    &.with-redirect {
      cursor: pointer;
    }
    &.new {
      animation: fade-in 1s ease-out 0s 1;
    }
    &:hover {
      background-color: theme.get-var('background-hover');

      > .notifications-read-status {
        border: 1px solid theme.get-var('border-strong');
      }
    }
    .gravatar-name > img {
      padding: 0;
    }
    .notification-message {
      color: theme.get-var('text-primary');
      b,
      u {
        @include body-emphasis-02;
      }
      @include body-02;
    }
    .notification-description {
      margin-top: $spacing-04;
    }
    .notification-info-tag {
      background: theme.get-var('highlight');
      color: theme.get-var('text-primary');
      border-left: 2px solid theme.get-var('notification-info-border');
      padding: $spacing-01 $spacing-02;
      border-radius: 6px;

      &__alt {
        background: theme.get-var('tag-background-purple');
        border-left-color: theme.get-var('tag-border-purple');
      }
    }
    .notifications-read-status {
      height: 22px;
      width: 22px;
      border-radius: 16px;
      border: 1px solid transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: $spacing-05;
      cursor: pointer;
      &:hover {
        background-color: theme.get-var('layer-accent-active-03');
        .notifications-tooltip {
          display: block;
        }
      }
      .notifications-unread-blip {
        height: 8px;
        width: 8px;
        border-radius: $border-radius-01;
        background-color: theme.get-var('icon-primary-bright');
      }
      .notifications-tooltip {
        color: theme.get-var('text-on-color');
        background-color: theme.get-var('background-inverse');
        position: absolute;
        top: 48px;
        right: -16px;
        padding: $spacing-03;
        @include body-02;
        display: none;
        &::after {
          content: '';
          height: 8px;
          width: 8px;
          transform: rotate(45deg);
          background-color: theme.get-var('background-inverse');
          position: absolute;
          right: 40px;
          top: -4px;
        }
      }
    }
  }
  &-fetch {
    min-height: 32px;
  }
  &-list-end {
    min-height: 48px;
    @include body-emphasis-03;
    color: theme.get-var('text-primary');
    gap: $spacing-05;
    padding: $spacing-05;
    > img {
      height: 2rem;
    }
  }
}

.notifications-toast {
  border-radius: $border-radius-01;
  position: fixed;
  right: 1rem;
  top: 4rem;
  animation: fade-in 1s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
