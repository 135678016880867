@use '@carbon/styles/scss/utilities/custom-property' as theme;
@import '../abstracts/_variables';

.crossmark {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: theme.get-var('text-error');
  stroke-miterlimit: 10;

  .crossmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: theme.get-var('text-error');
  }

  .crossmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
  }

  .cross__path {
    stroke: #ffffff;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    transform-origin: 50% 50% 0;
  }

  &.animated {
    animation: fillred 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;

    .crossmark__circle {
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }
    .crossmark__check {
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }
    .cross__path--right {
      animation: 0.3s ease 0.8s normal forwards 1 running stroke;
    }
    .cross__path--left {
      animation: 1s ease 0.8s normal forwards 1 running stroke;
    }
  }

  &.static {
    box-shadow: inset 0px 0px 0px 30px theme.get-var('text-error');
    .crossmark__circle,
    .crossmark__check,
    .cross__path--right,
    .cross__path--left {
      stroke-dashoffset: 0;
    }
  }
}

@keyframes fillred {
  100% {
    box-shadow: inset 0px 0px 0px 30px theme.get-var('text-error');
  }
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
