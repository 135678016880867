.maintenance-notification {
  &.cds--inline-notification {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99;
  }
}
