@use '@carbon/styles/scss/spacing' as *;

.gravatar-name {
  position: relative;
  display: flex;
  align-items: center;
  > img {
    padding: $spacing-02 $spacing-03 $spacing-02 0;
  }
  .checkmark,
  .crossmark {
    position: absolute;
    left: 1.25rem;
    top: 1.5rem;
  }
}
