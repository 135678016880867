@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/colors' as carbonColor;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/mixins';
@import '../abstracts/variables';
@import '../../styles/base/colors';

.CustomGroups {
  margin-top: $spacing-07;
  box-shadow: $boxShadow-01;
  border-radius: $border-radius-01;
  background-color: theme.get-var('background');
  padding: $spacing-03;
  border: 1px solid theme.get-var('border-subtle');
}
.CustomGroups__text-input-wrapper {
  display: flex;
  align-items: center;
  .CustomGroups__text-input {
    width: 350px;
    padding-right: $spacing-03;
  }
}
.CustomGroupsUploadTable__cell-text-input {
  display: flex;
  align-items: center;
  .cds--form-item {
    flex: none;
  }
  .cds--text-input {
    min-width: fit-content;
  }
  svg {
    margin-left: $spacing-05;
    cursor: pointer;
  }
  &--edit {
    width: 350px;
  }
}

.CustomGroups__icon-wrapper {
  background-color: theme.get-var('highlight');
  color: theme.get-var('icon-primary-bright');
  padding: $spacing-03;
  border-radius: $spacing-03;
  width: fit-content;
  max-height: 40px;
}
.CustomGroups__title-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-03;
  .CustomGroups__title-wrapper {
    display: flex;
  }
  .CustomGroups__title {
    margin-left: $spacing-03;
    line-height: 20px;
    .CustomGroups__label-wrapper {
      color: theme.get-var('text-primary');
      .CustomGroups__label {
        @include heading-02;
        padding-right: $spacing-02;
      }
      span {
        display: inline-block;
        @include body-02;
      }
    }
    @include body-03;
    color: theme.get-var('text-secondary');
  }
}

.CustomGroups__section {
  padding: 0 $spacing-03;
  .CustomGroups__icon {
    position: relative;
    top: 38px;
    left: 10px;
    z-index: 1;
  }
  .CustomGroups__icon-btn {
    margin: 0 $spacing-02;
  }

  .CustomGroups__filter-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: $spacing-05;
    .CustomGroups__filter {
      margin: $spacing-05 0;
      display: flex;
      min-width: 35vw;
      .CustomGroups__filter-search {
        width: 400px;
      }
    }
    .CustomGroups__bulk-actions {
      border: 1px solid theme.get-var('border-interactive-primary');
      box-shadow: $boxShadow-03;
      border-radius: $border-radius-01;
      margin: $spacing-02 0 $spacing-05 0;
      padding: $spacing-03;
      display: flex;
      justify-content: flex-end;
      .CustomGroups__bulk-actions-button-wrapper {
        .CustomGroups__bulk-actions-button {
          display: flex;
        }
        .CustomGroups__bulk-actions-divider {
          border-left: 1px solid theme.get-var('divider');
          height: auto;
          margin: $spacing-02;
        }
      }
    }
  }
}
.CustomGroups__item-loader {
  margin-left: auto;
  padding-right: $spacing-09;
}
.CustomGroups__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: $border-radius-01;
  padding: $spacing-05 $spacing-07;
  margin-bottom: $spacing-04;
  min-height: 100px;
  flex-wrap: wrap;
  &:hover {
    cursor: pointer;
    background-color: theme.get-var('layer-hover-01');
  }
  .cds--label {
    @include body-02;
    padding-left: $spacing-07;
  }
  border: 1px solid theme.get-var('border-subtle');
  &--info {
    @include body-03;
    color: theme.get-var('text-secondary');
  }
  &--divider {
    margin: 0 $spacing-02;
    color: theme.get-var('border-interactive-primary');
  }
}
.CustomGroups__item-title-wrapper {
  margin-right: auto;
  display: flex;
  align-items: center;
  .CustomGroups__item-title-hover {
    display: flex;
    align-items: center;
  }
}
.CustomGroupsEdit {
  display: flex;
}

.CustomGroupsEdit__text-input {
  width: 350px;
  display: flex;
  align-items: center;
  .cds--form-item {
    flex: none;
  }
  .cds--text-input {
    min-width: fit-content;
  }
  svg {
    margin-left: $spacing-05;
    cursor: pointer;
  }
}

.CustomGroups__tag-wrapper {
  @include body-emphasis-02;
  display: flex;
  align-items: center;
  padding-top: $spacing-05;
}
.CustomGroups__tag {
  display: flex;
  align-items: center;
  @include body-emphasis-03;
  background-color: $main-gray-5;
  padding-right: $spacing-06;
  border-radius: $border-radius-01;
  border-left: 2px solid theme.get-var('border-strong');
  margin-right: $spacing-05;
}
.CustomGroups__access-btn {
  height: 40px;
  min-width: 105px;
}
.CustomGroups__tag-icon {
  margin: $spacing-03 $spacing-05;
  cursor: pointer;
}

.CustomGroups__footer {
  margin: $spacing-05 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  .CustomGroups__footer-next-btn {
    min-width: 100px;
    cursor: pointer;
  }
  .CustomGroups__footer-back-btn {
    padding: 0 $spacing-06;
    margin-right: $spacing-05;
    width: fit-content;
  }
}

.CustomGroupsUpload__dropdown-wrapper {
  display: flex;
  margin-bottom: $spacing-07;
  align-items: center;
  span {
    @include heading-02;
    padding-left: $spacing-03;
  }
}
.CustomGroupsUpload__dropdown {
  width: 288px;
}
.CustomGroupsUploadValidator__title {
  margin-top: $spacing-09;
  display: flex;
  align-items: center;
  span {
    @include heading-02;
    padding-left: $spacing-03;
  }
}
.CustomGroupsUploadValidator__edit-title {
  margin: $spacing-05 0;
  padding-top: $spacing-05;
  .CustomGroupsUploadValidator__edit-title-text {
    display: flex;
    align-items: center;
    .CustomGroupsUploadValidator__edit-title-text-title {
      @include heading-05;
      padding-left: $spacing-03;
    }
  }
  .CustomGroupsUploadValidator__edit-title-text-description {
    @include body-02;
    padding: $spacing-04 0;
  }
}
.CustomGroupsUploadTransform-edit-container {
  display: flex;
  align-items: center;
  margin-top: $spacing-05;
  .CustomGroupsUploadTransform-edit-name {
    @include body-02;
    margin-right: $spacing-05;
  }
}

.CustomGroupsUploadTransform-notification {
  margin: $spacing-05 0;
}

.CustomGroupsUploadValidator__upload-container {
  @extend .CustomGroups;
  padding: $spacing-05 $spacing-07;
  min-height: 350px;
  .CustomGroupsUploadValidator__upload-info {
    margin-bottom: $spacing-05;
  }
  .CustomGroupsUploadValidator__upload-preview-table {
    width: 500px;
    margin: $spacing-05 0;
    border: 1px solid theme.get-var('border-subtle');
  }

  .CustomGroupsUploadValidator__drag-and-drop-info {
    @include body-02;
    &--highlighted {
      color: theme.get-var('link-visited');
    }
  }
  .CustomGroupsUploadValidator__drag-and-drop-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: $spacing-07;
  }

  .CustomGroupsUploadValidator__drag-and-drop-button {
    position: relative;
    width: 28px;
    height: 28px;
    border-radius: $border-radius-01;
    margin-bottom: $spacing-03;
    background-color: theme.get-var('highlight');
    color: theme.get-var('icon-primary-light');
    padding: $spacing-02;
    &:hover {
      background-color: theme.get-var('table-header-active');
    }
  }
  .cds--file__drop-container {
    height: 158px;
    border-radius: $border-radius-02;
    border-color: theme.get-var('border-interactive-primary');
  }
  .cds--file {
    label {
      border-radius: $border-radius-02;
      text-align: center;
      max-width: 100vw;
      width: 100%;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .cds--file__selected-file {
    min-width: 100%;
    border-radius: $border-radius-02;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .cds--file-close {
    margin-left: $spacing-02;
    max-width: 18px;
    background-image: url('../../assets/icons/trash-can.svg');
    background-repeat: no-repeat;
    background-position: center;
    svg {
      padding: $spacing-03;
      display: none;
    }
    &:hover {
      svg {
        cursor: pointer;
      }
    }
  }
}

.CustomGroupsUploadSuccess {
  @extend .CustomGroupsUploadValidator__upload-container;
  padding: $spacing-10 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .CustomGroupsUploadSuccess__success-title {
    @include heading-05;
    color: theme.get-var('text-button-primary');
  }
  .CustomGroupsUploadSuccess__success-info {
    margin: $spacing-05;
    @include body-01;
  }
  .CustomGroupsUploadSuccess__success-btn-wrapper {
    display: flex;
    justify-content: space-between;
    min-width: 320px;
  }
}

.CustomGroupsUploadTransform {
  @include body-01;
  margin: $spacing-05 0;
}

.CustomGroupsUploadTransform__checkbox {
  margin-bottom: $spacing-07;
}
.CustomGroupsUploadTransform__form-wrapper {
  margin: $spacing-05 0 $spacing-05 $spacing-05;
  .CustomGroupsUploadTransform__form {
    display: flex;
    justify-content: space-between;
    width: 540px;
  }
  .CustomGroupsUploadTransform__input {
    max-width: 400px;
  }
  button {
    min-width: 125px;
  }
}
.CustomGroups__metadata {
  margin-top: $spacing-05;
  &--divider {
    color: theme.get-var('border-interactive-primary');
    margin: 0 $spacing-02;
  }
  > .body-03 {
    display: flex;
  }
}
.CustomGroupsTable {
  margin: $spacing-05 0;
  @extend .CustomGroups;
  .CustGroupTable__title-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: $spacing-05 $spacing-03 $spacing-02 $spacing-03;

    .CustGroupTable__title {
      @include heading-05;
      display: flex;
      justify-content: space-between;
      .CustGroupTable__title-text {
        display: flex;
        span:nth-child(2) {
          padding: 0 $spacing-03;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .CustomGroupTable__metadata-wrapper {
    padding-left: $spacing-03;
  }

  .CustomGroupTable__edit-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-right: $spacing-03;
    margin-bottom: $spacing-03;
  }

  .CustomGroupTable__edit-icon {
    margin-left: $spacing-02;
    &:hover {
      cursor: pointer;
    }
  }

  .CustGroupTable__table {
    .cds--data-table-content {
      margin: 0 $spacing-05;
    }
    .cds--table-toolbar {
      margin: 0 $spacing-05;
    }
    .cds--data-table > thead > tr > th .cds--table-sort__flex {
      min-width: 250px;
    }
  }
}
.CustGroupTable__footer {
  margin: $spacing-05 0;
  display: flex;
  justify-content: flex-end;
}

.CustomGroupsUploadTable {
  @extend .CustomGroups;
  .CustomGroupsUploadTable__title {
    @include heading-04;
    margin: $spacing-05;
  }
  .CustomGroupsUploadTable__notification {
    width: auto;
    margin-right: $spacing-07;
    margin-bottom: $spacing-05;
    .cds--inline-notification__details .cds--inline-notification__title {
      color: theme.get-var('text-error');
      @include body-02;
    }
    .cds--inline-notification {
      margin-left: $spacing-05;
    }
  }
  .CustomGroupsUploadTable__table {
    .cds--data-table-content {
      margin: 0 $spacing-05;
    }
    .cds--table-toolbar {
      margin: 0 $spacing-05;
    }
    .cds--data-table > thead > tr > th .cds--table-sort__flex {
      min-width: 250px;
    }
  }
}
.CustomGroupsUploadTable__edit-wrapper {
  display: flex;
  align-items: center;
  svg {
    margin-left: $spacing-03;
  }
  .CustomGroupsUploadTable__edit-icon {
    display: none;
  }
  &:hover {
    cursor: pointer;
    .CustomGroupsUploadTable__edit-icon {
      display: block;
      margin-left: $spacing-02;
    }
  }
}

.CustomGroupsUploadTable__invalid-row {
  background-color: theme.get-var('notification-error-background') !important;
  .cds--checkbox:checked + .cds--checkbox-label::before {
    background-color: theme.get-var('notification-error-border');
  }
  &--text {
    color: theme.get-var('text-error');
  }
}

.NoCustomGroupsPlaceholder {
  margin: $spacing-10;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing-05;
  flex-direction: column;
  .NoCustomGroupsPlaceholder__image {
    height: 87px;
  }
  .NoCustomGroupsPlaceholder__button-wrapper {
    padding-top: $spacing-05;
  }
}
