@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@use '@carbon/colors' as carbonColor;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.ScheduleReport {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .ScheduleReport__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: $spacing-04;
    .ScheduleReport__title {
      margin: $spacing-05 0;
      @include heading-05;
    }
  }

  .option-row {
    display: flex;
    align-items: center;
    gap: $spacing-04;
    @include body-02;
    input[type='number'],
    span {
      color: theme.get-var('text-secondary');
      @include body-03;
    }
    &.column {
      flex-direction: column;
      align-items: start;
    }

    .cds--radio-button-group,
    &.recurrence-type {
      width: 100%;
      align-items: start;

      div {
        flex-basis: 50%;

        .cds--radio-button__label {
          justify-content: flex-start;
        }

        .cds--label {
          margin-bottom: $spacing-04;
          min-height: 35px;
        }

        img {
          max-width: 250px;
          max-height: 200px;
          height: auto;
        }
      }
    }
  }
  .ScheduleReport__section-title {
    margin: $spacing-03 0;
    display: flex;
    span {
      @include body-emphasis-02;
      padding-left: $spacing-03;
    }
  }
  .ScheduleReport__summary {
    border-radius: $border-radius-01;
    border: 1px solid theme.get-var('border-subtle');
    .ScheduleReport__summary-title {
      border-radius: $border-radius-01 $border-radius-01 0 0;
      background-color: carbonColor.$blue-10;
      @include heading-01;
      display: flex;
      align-items: center;
      padding: $spacing-03;
      span {
        padding-left: $spacing-02;
      }
    }
    .ScheduleReport__summary-content {
      @include body-02;
      padding: $spacing-03;
      div {
        padding: $spacing-02 0;
      }
    }
    .ScheduleReport__summary-cancel-btn-container {
      display: flex;
      justify-content: flex-end;
      padding: $spacing-03;
    }
  }

  .ScheduleReport__date-picker-container {
    @extend .option-row;
    margin-top: $spacing-03;
    width: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .ScheduleReport__date-picker {
      display: flex;
      align-items: center;
      justify-content: center;
      .cds--toggle {
        margin: 0 0 $spacing-03 $spacing-03;
      }
      span {
        @include body-01;
        color: theme.get-var('text-primary');
      }
    }
  }
  .ScheduleReport__notes {
    @extend .option-row;
    margin-bottom: $spacing-03;
  }

  .RecurrenceOptions {
    margin-top: $spacing-03;
    display: flex;
    flex-direction: column;
    gap: $spacing-03;

    .cds--radio-button__label {
      width: fit-content;
    }

    .date-picker-container .flatpickr-calendar {
      top: unset !important;
      bottom: 40px;
    }
    .RecurrenceOptions__radio-container {
      @extend .option-row;
    }
    .RecurrenceOptions__img {
      display: flex;
      flex-direction: column;
    }
  }

  .section-title {
    position: relative;
    @include body-02;
  }

  .option-label {
    @include body-02;
  }

  .repeats-number-input {
    max-width: 170px;

    .cds--number__input-wrapper {
      min-width: 170px;
    }
  }

  .radio-button-label {
    display: flex;
    align-items: center;
    gap: $spacing-03;
  }

  .cds--radio-button-wrapper .cds--radio-button__label {
    align-items: center;
  }

  .cds--toggle__label-text {
    margin-bottom: $spacing-03;
  }

  .ScheduleReport__footer {
    border-top: 1px solid theme.get-var('border-subtle');
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    padding: $spacing-05 0;
    background: theme.get-var('background');
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  .reset-button {
    padding: 9px;
  }
  .cds--text-area {
    border-radius: $border-radius-01;
    border: 1px solid theme.get-var('border-subtle');
  }
}
