@use '@carbon/styles/scss/spacing' as *;

.not-found {
  padding: $spacing-06;
}

.not-found > :first-child {
  padding-bottom: $spacing-03;
}

.not-found > :nth-child(2) {
  display: flex;
  gap: $spacing-06;
}
