@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/_variables';
@import '../base/_colors.scss';
.FormStepper {
  margin: $spacing-05 0;
  .cds--progress {
    margin-bottom: $spacing-07;
  }

  .FormStep {
    box-shadow: $boxShadow-01;
    background-color: theme.get-var('background');
    border-radius: $border-radius-01;
    .IconTitle {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background: $main-gray-5;
      border-bottom: 1px solid theme.get-var('border-subtle');
    }
    .FormStep__content {
      padding: $spacing-05;
    }
  }
  .FormStepper__action-container {
    margin-top: $spacing-05;
    display: flex;
    justify-content: space-between;
  }
}
