@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.dimension-group {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dimension-group > svg {
  cursor: pointer;
}

.hierarchy-node {
  margin: 8px;
  border-radius: $border-radius-02;
  border: 1px solid #eeeeee;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
}

.hierarchy-node.error {
  border: 0.5px solid theme.get-var('border-error');
  color: theme.get-var('text-error');
  background-color: theme.get-var('background');
  font-size: 12px;
  text-transform: none;
}

.hierarchy-node.checked {
  border: 1px solid theme.get-var('border-interactive-primary');
}

.hierarchy-node.invalid {
  background-color: theme.get-var('background-disabled');
  border: 1px solid theme.get-var('border-disabled');
  color: theme.get-var('text-on-color-disabled');
  cursor: default;
  .cds--tooltip svg {
    fill: theme.get-var('main-button-danger-primary');
  }
  &:hover {
    background-color: theme.get-var('background-disabled');
  }
}

.hierarchy-node-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.hierarchy-node-access {
  .cds--tag__custom-icon {
    svg {
      height: 16px;
      width: 16px;
    }
  }
}

.hierarchy-label-container {
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 20px;
  flex-grow: 1;

  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.favourites span {
    display: flex;
    align-items: center;
    @include body-emphasis-01;
    svg {
      margin-left: $spacing-03;
    }
  }

  .node-table-edit-name-container {
    flex-grow: 1;
    .node-table-edit-name-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: $spacing-03;
    }
    .node-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div:first-of-type {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &--edit {
        height: 16px;
      }
    }
  }
}

.expand-chevron {
  margin-right: 8px;
  cursor: pointer;
  position: absolute;
  left: 0;
  .disabled {
    color: theme.get-var('border-subtle');
  }
}

.node-actions {
  display: flex;
  align-items: center;
  .cds--tooltip-content {
    max-width: 335px;
  }
}

.node-checkbox {
  padding-right: $spacing-05;
}
