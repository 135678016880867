@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../base/typography';

.HomeData__filters-column.cds--css-grid-column,
.Home__heading-container.cds--css-grid-column {
  margin: 0;
}

.home-heading {
  @include heading-06;
  margin-bottom: $spacing-06;
}
.home-section-heading {
  @include heading-01;
  margin-top: $spacing-05;
  border-bottom: 1px solid theme.get-var('border-subtle');
  padding-bottom: $spacing-02;
}
.home-data-table {
  margin-top: $spacing-06;
  box-shadow: $boxShadow-01;
}

.HomeDropdowns.cds--stack-horizontal {
  width: 100%;
  grid-auto-flow: dense;
}

.HomeDropdowns {
  .HomeDropdowns__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-05 0;
    position: relative;
    width: 100%;
    grid-column: 1 / -1;
  }
  .HomeDropdowns__dropdown-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: $spacing-05 0;
    .cds--dropdown .cds--list-box__field {
      border-radius: $border-radius-02;
    }
  }
  .HomeDropdowns__total-dropdown {
    margin-bottom: $spacing-06;
    .cds--dropdown__wrapper {
      margin: 0;
    }
  }
}

.HomeDropdowns__time-period {
  margin-bottom: $spacing-06;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: $spacing-04;
  color: theme.get-var('text-secondary');

  .HomeDropdowns__date-range-container {
    height: 30px;
    display: flex;
    align-items: center;
    gap: $spacing-03;
    background-color: theme.get-var('background');
    border: 1px solid theme.get-var('border-subtle-selected');
    border-radius: $border-radius-02;
    padding: $spacing-03 $spacing-05;

    .HomeDropdowns__date-range-label {
      @include body-03;
      > span {
        @include body-emphasis-03;
      }
    }
    > svg {
      fill: theme.get-var('icon-primary-light');
    }
    &:last-of-type {
      > svg {
        fill: theme.get-var('icon-secondary-dark');
      }
    }
  }
  .cds--skeleton__placeholder {
    height: 30px;
    width: 230px;
    border-radius: $border-radius-02;
    &::before {
      border-radius: $border-radius-02;
    }
  }
}

.skeleton-loading {
  margin-bottom: $spacing-08;
}

.cds--skeleton__text.home-filters--category {
  height: $spacing-07;
  border-radius: $spacing-02;
}
.cds--skeleton__text {
  border-radius: $border-radius-02;
  &::before {
    border-radius: $border-radius-02;
  }
}

.cds--skeleton__text.home-section-heading {
  &::before {
    border-radius: $border-radius-02;
  }
  height: $spacing-09;
  border-radius: $border-radius-02;
}

.loading-action-card-container {
  height: auto;
  width: 100%;
  display: flex;
  margin-top: $spacing-06;
  div {
    &:last-child {
      margin-right: 0px;
    }
  }
}

.cds--skeleton__placeholder.loading-action-card {
  &::before {
    border-radius: $border-radius-02;
  }
  flex: 1;
  border-radius: $border-radius-02;
  margin-right: $spacing-06;
  &.modules {
    height: 12rem;
    width: 10rem;
  }
}

.align-center {
  align-items: center;
}

.float-right {
  float: right;
}

.helper-text {
  color: theme.get-var('text-secondary');
}

.HomeCategoryDropdown__container {
  position: relative;
  user-select: none;

  .HomeCategoryDropdown__button {
    @include body-02;
    display: flex;
    margin-right: $spacing-03;
    height: 40px;
    align-items: center;
    color: theme.get-var('text-secondary');
    border: 1px solid theme.get-var('border-subtle');
    border-radius: $border-radius-01;
    background: theme.get-var('background');
    padding: 0 $spacing-05;
    gap: $spacing-04;
    width: 378px;
    cursor: pointer;

    & > div {
      flex-grow: 1;

      &.empty {
        color: theme.get-var('text-placeholder');
      }
    }
    svg:first-of-type {
      color: theme.get-var('icon-primary-bright');
    }
  }
}

.HomeDropdowns__breadcrumb {
  @include body-03;
  margin-top: $spacing-07;
  display: flex;
  gap: $spacing-02;
  align-items: baseline;

  .cds--breadcrumb-item .cds--link {
    @include body-03;
    margin-bottom: 0;
    color: theme.get-var('text-helper');
    cursor: text;
    &:hover {
      text-decoration: none;
    }
  }
}

.CategoryHierarchy__container {
  z-index: 4;
  position: absolute;
  padding: 0 $spacing-06 $spacing-06;
  background-color: theme.get-var('background');
  box-shadow: $boxShadow-03;
  border-radius: $border-radius-01;
  top: 0;
  width: 100%;
  animation: hierarchy-container-animation 0.5s;
  overflow-y: auto;

  .CategoryHierarchy__searchbar {
    padding: $spacing-07 0 $spacing-06;
    position: sticky;
    top: 0;
    background-color: theme.get-var('background');
    z-index: 3;
    max-width: 70%;
    margin: auto;
    .cds--search-close:hover {
      border: 1px solid;
    }
  }
  .CategoryHierarchy__tree {
    max-width: 70%;
    margin: auto;

    .CategoryHierarchy__node {
      padding: 10px 0;
      display: flex;
      align-items: center;
      height: 44px;
      user-select: none;
      gap: $spacing-03;

      .CategoryHierarchy__expand-chevron {
        cursor: pointer;
        height: 44px;
        width: 44px;
        border-radius: $border-radius-01;
        transition: background-color 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          pointer-events: none;
        }
        &:hover {
          background-color: theme.get-var('layer-selected-hover-01');
        }
      }
      &.error {
        border: none;
        div {
          color: var(--cds-text-error);
          background-color: var(--cds-background);
          font-size: 12px;
          text-transform: none;
        }
      }
      &.collapsed {
        border-bottom: 1px solid theme.get-var('border-subtle');
      }
      &.selected {
        background-color: theme.get-var('layer-selected-primary');
        border-bottom: none;
        border-radius: $border-radius-01;
      }
    }
  }
}

.HomeReportContent__container {
  position: relative;
  margin: $spacing-07 0;
  border: 1px solid theme.get-var('border-subtle');
  border-radius: $border-radius-01;
  background-color: theme.get-var('background');
  padding: 0 $spacing-05;
  .cds--subgrid--wide {
    margin-bottom: 0;
  }
  .card {
    margin-top: 0;
  }
  &--one-visual-container {
    .visual-container.homepage {
      margin: 0;
    }
  }
  &--cards-container {
    border: none;
    padding: 0;
  }
  &--no-background {
    border: none;
    background-color: transparent;
    padding: 0;
    .visual-container.homepage {
      border: 1px solid theme.get-var('border-subtle');
      border-radius: $border-radius-01;
      padding: 0 $spacing-05;
      margin: 0;
    }
  }
  .HomeReportContent__shared-dropdown-wrapper {
    position: absolute;
    top: 48px;
    left: 0;
  }
}

.HomepageLoadingSkeleton {
  display: flex;
  flex-direction: column;
  gap: $spacing-07;
  padding-bottom: $spacing-07;

  .cds--skeleton__placeholder {
    width: 100%;
    border-radius: $border-radius-02;
    &::before {
      border-radius: $border-radius-02;
    }
    &.HomepageLoadingSkeleton__cards {
      height: 165px;
    }
    &.HomepageLoadingSkeleton__visual-container {
      height: 500px;
    }
  }
  .cds--data-table-container {
    border: 1px solid theme.get-var('border-subtle');
    border-radius: $border-radius-01;
  }
}

@keyframes hierarchy-container-animation {
  0% {
    top: 10px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
