@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/mixins';
@import '../abstracts/variables';

.ReportSettings {
  padding: $spacing-05;
}

.report-builder-wrapper {
  padding-top: $spacing-07;
  @include body-02;
}
.report-builder-container {
  box-shadow: $boxShadow-01;
  border-radius: $border-radius-01;
  background-color: theme.get-var('background');
  .dimensions-container {
    padding: $spacing-05;
    .cds--tabs {
      justify-content: center;
      .cds--tab--list {
        .cds--tabs__nav-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-transform: capitalize;
          color: theme.get-var('icon-primary-bright');
          padding: $spacing-05;
          .dimension-label {
            color: theme.get-var('text-primary');
            margin-left: $spacing-04;
          }
          &--selected {
            border-color: theme.get-var('border-interactive-secondary');
          }
          &--disabled {
            color: theme.get-var('border-disabled');
            pointer-events: none;
          }
        }
      }
    }
  }
}

.controls-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 70vh;

  .selection-container {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    border-left: 0.5px solid theme.get-var('border-subtle');
  }
  .hierarchy-container {
    padding: $spacing-03;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: $spacing-03;
    .cds--inline-loading {
      padding-left: $spacing-05;
    }
    #hierarchy-dropdown {
      z-index: 3;
      border: 1px solid theme.get-var('border-subtle');
      border-radius: $border-radius-02;
    }
  }
}

.hierarchy-search {
  margin: $spacing-03;
  padding-right: $spacing-05;
  .cds--search-close {
    margin-right: 16px;
  }
  &.invalid {
    .cds--search-input:focus,
    .cds--search-input:hover,
    .cds--search-input:target {
      outline: 2px solid theme.get-var('border-error');
      outline-offset: -2px;
      z-index: 1;
    }
  }
  &-error {
    font-size: 12px;
    color: theme.get-var('border-error');
    margin-left: 8px;
  }
}

.hierarchy-controls {
  margin-right: $spacing-03;

  .hierarchy-clear-all-button {
    height: 32px;
    padding: $spacing-03;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .clear-all {
      height: 16px;
    }
  }
}

.selection-controls {
  margin: $spacing-05;
  > div {
    margin-bottom: $spacing-05;
  }
}

.summary-block-wrapper > div {
  margin-bottom: $spacing-05;
}

.selections .parameter-type {
  margin-bottom: $spacing-05;
}

.parameter-type {
  padding: $spacing-04;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid theme.get-var('border-subtle');
  margin-bottom: $spacing-05;
}

.parameter-type > div {
  padding: $spacing-03;
  border-radius: 4px;
  color: theme.get-var('text-primary');
  @include body-01;
}
.hierarchy-clear-all-button,
.parameter-type {
  .clear-all {
    text-decoration: underline;
    &:active {
      color: theme.get-var('background');
    }
  }
}

.selection-actions {
  margin-bottom: $spacing-06;
}

.add-action {
  height: $spacing-08;
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  padding: $spacing-03 $spacing-05;
  border-radius: $border-radius-01;
  align-items: center;
  transition: background-color 0.1s;
  color: theme.get-var('background-primary');
  margin-top: $spacing-05;
}

.add-action-button {
  flex-grow: 1;
  @include body-emphasis-02;
  color: theme.get-var('text-primary');
  display: flex;
  align-items: center;
}

.add-action-children {
  display: flex;
  justify-self: flex-end;
  flex-shrink: 1;
  max-width: 200px;
}

.add-label span {
  margin-left: $spacing-04;
}

.calendar-container {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;
}

.dimensions-label {
  border-radius: $border-radius-01;
  margin-right: $spacing-05;
  color: theme.get-var('text-secondary');
}

.parameter-block {
  background-color: white;
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 16px;
  margin: 2px 0;
  flex-grow: 1;
  .cds--text-input {
    border: 1px solid transparent;
    border: none;
    height: 2.25rem;
    &:hover {
      outline: 1px solid theme.get-var('border-subtle');
    }
    &:focus {
      outline: 2px solid theme.get-var('focus');
      border-radius: $border-radius-01;
    }
  }
  &.dimensions-label {
    height: 40px;
    background-color: theme.get-var('background-primary');
    color: theme.get-var('background');
    padding-left: $spacing-09;
    border-bottom: none;
  }
  &.editable-label {
    padding-left: $spacing-09;
    height: 40px;
    border-bottom: none;
    @include body-02;
  }
}

.parameter-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: $spacing-03;
  text-transform: capitalize;
  padding-left: $spacing-07;
}

.parameter-label.cds--text-input-wrapper--readonly input {
  color: theme.get-var('background');
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: none;
}

.parameter-actions {
  cursor: pointer;
  display: flex;
  gap: $spacing-03;

  .cds--inline-loading {
    min-height: 20px;
    .cds--inline-loading__animation {
      margin: 0;
    }
  }
  .pin-filled {
    color: theme.get-var('icon-primary-bright');
  }
}

.report-summary {
  width: fit-content;
  cursor: default;
  margin-bottom: $spacing-05;
  display: flex;
  align-items: center;
  gap: $spacing-03;
}

.submit-report-summary {
  width: 100%;
  margin: $spacing-07 0;
  display: flex;
  &-parameters,
  &-info {
    padding: 0 $spacing-06;
    width: 50%;
  }
  &-notifications-wrapper {
    padding: $spacing-03 0;
  }
  &-info {
    position: relative;
    border-left: 0.5px solid theme.get-var('border-subtle');
    .submit-container {
      top: 65px;
      position: sticky;
      padding-bottom: $spacing-05;
      .report-requirement-container {
        display: block;
        margin-bottom: $spacing-03;
        .report-requirement {
          display: flex;
          gap: $spacing-03;
          padding: $spacing-02;
        }
      }
      .report-restrictions-notification {
        margin-bottom: $spacing-03;
      }
    }
  }
}

.report-setting-inputs-wrapper {
  margin: $spacing-05 0;
}

.report-actions {
  padding: $spacing-05 0;
  background: theme.get-var('background');
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.quick-date-selection {
  height: 240px;
  width: 380px;
  border: 0.5px solid theme.get-var('border-subtle');
  border-radius: $border-radius-01;
  margin: $spacing-05;
  box-shadow: $boxShadow-01;
}

.quick-date-selection .date-selection:not(:first-child) {
  border-top: 0.5px solid theme.get-var('border-subtle');
}

.date-selection {
  height: $spacing-09;
  padding: $spacing-04 $spacing-05;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include body-02;
}

.week-selector {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  align-items: center;
  gap: 0;
  width: 100%;
}

.week-selector input {
  margin-right: $spacing-03;
}

.summary-title {
  @include heading-03;
  margin-top: $spacing-03;
  padding: $spacing-03;
  border-bottom: 1px solid theme.get-var('border-subtle');
  margin-bottom: $spacing-05;
}

.summary-title-text {
  text-align: center;
}
.SettingsSummary__wrapper {
  margin-left: $spacing-06;
}

.SettingsSummary {
  list-style-type: disc;
  @include label-02;
}
.parameter-collection {
  .cds--inline-notification {
    margin-top: $spacing-05;
  }
  .ParameterBlock__toggle {
    display: flex;
    align-items: center;
    .cds--toggle__label-text {
      margin: 0;
    }
    .cds--toggle__text {
      min-width: 55px;
    }
    .ParameterBlock__toggle-label {
      display: flex;
      align-items: center;
      height: 24px;
      @include body-02;
    }
    .cds--toggle__switch {
      width: 3rem;
      &.cds--toggle__switch--checked::before {
        transform: translateX(2rem);
      }
    }
  }
  .ParameterBlock__toggle-info-tooltip {
    align-self: center;
    height: 24px;
    .cds--tooltip svg {
      width: 13px;
      height: 13px;
      margin-left: $spacing-01;
    }
  }
}
.summary-block-wrapper {
  width: 70%;

  .overflow-menu-container {
    display: flex;
    justify-content: flex-end;
    height: 40px;
    align-items: center;

    .cds--inline-loading {
      width: fit-content;
    }
  }
}
.SettingsSummary__type {
  color: theme.get-var('text-primary');
  text-transform: capitalize;
  @include body-emphasis-02;
  padding: $spacing-03 0 0 $spacing-03;
  span {
    @include body-03;
    color: theme.get-var('text-secondary');
  }
  .SettingsSummary__label {
    @include body-02;
    text-transform: none;
  }
}

.optional-params {
  margin-top: $spacing-05;
  @include body-emphasis-03;
}
.requirements-list {
  list-style-type: disc;
  padding-left: 40px;
}
.strong-text {
  @include body-emphasis-03;
}
.advanced-settings-radio-buttons {
  text-transform: capitalize;
}
.advanced-settings-radio-group {
  margin-top: $spacing-05;
}

.transaction-spend-group {
  display: flex;
  margin: $spacing-07 0 $spacing-02 $spacing-03;
}
.transaction-spend-checkbox {
  margin-left: $spacing-03;
}
.spend-band-number-input {
  min-height: 85px;
}

.report-setting-checkbox-title {
  margin: $spacing-03 0;
}
.report-setting-checkbox-wrapper {
  .cds--checkbox-label {
    @include body-03;
  }
}
