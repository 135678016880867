@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.quickfilter {
  margin: $spacing-05 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.quickfilter__button {
  cursor: pointer;
  @include body-02;
  padding: $spacing-04;
  transition: background-color 0.3s ease;
  border-radius: $border-radius-02;
  background-color: transparent;
  color: theme.get-var('text-primary');

  &:hover {
    background-color: theme.get-var('background-active');
  }
  &.active {
    @include body-emphasis-02;
    background-color: theme.get-var('layer-02');
  }

  &:focus {
    border-color: transparent;
    box-shadow: none;
  }
}

.quickfilter__divider {
  border-left: 1px solid theme.get-var('divider');
  height: 2rem;
  margin: 0 $spacing-03;
}
