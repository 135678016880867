@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@use '@carbon/colors' as carbonColor;
@import '../abstracts/_variables';
@import '../abstracts/mixins';

.performance-indicator {
  padding: $spacing-03;
  border-radius: $border-radius-01;

  display: flex;
  align-items: center;
  &.positive {
    color: theme.get-var('text-success');
    background-color: theme.get-var('notification-success-background');
  }
  &.negative {
    color: theme.get-var('text-error');
    background-color: theme.get-var('notification-error-background');
  }
  &.neutral {
    color: theme.get-var('text-info');
    background-color: carbonColor.$gray-10;
  }
  &.lg {
    height: 40px;
    font-size: $font-size-09;
  }
  .perforemance-indicator--value {
    margin-right: $spacing-02;
  }
}
