@use '@carbon/styles/scss/spacing' as *;
@use '@carbon/colors' as carbonColor;
@import '../base/colors';
@import '../abstracts/variables';
@import '../abstracts/mixins';

.Popover {
  .Popover--content {
    @include body-02;
    border-radius: $border-radius-01;
    max-width: 800px;
    z-index: 8000;

    ul {
      &.list-style-disc {
        line-height: 18px;
        padding: $spacing-03 $spacing-05;
        list-style: disc;
        list-style-position: inside;

        li {
          margin: $spacing-04 0;
        }
      }
    }

    &.Popover--content__shaded {
      background: carbonColor.$blue-10;
    }
  }

  &.cds--popover--top .cds--popover-content {
    padding: 6px;
    top: 0;
    left: 50%;
  }

  .cds--popover-caret {
    background: carbonColor.$blue-10;
  }

  .cds--btn.cds--btn--ghost {
    color: carbonColor.$blue-60;
  }
}
