@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.GenAI {
  &-form {
    &__dropdown {
      &.cds--dropdown__wrapper {
        margin: 0;

        .cds--dropdown {
          max-width: 100%;
        }
      }
    }

    &__actions {
      display: flex;
      gap: $spacing-05;

      @media (max-width: 560px) {
        flex-wrap: wrap;
      }
    }

    .cds--label,
    .cds--toggle__label-text {
      @include body-02;
      color: theme.get-var('text-primary');
    }

    .cds--text-input,
    .cds--checkbox-label-text,
    .cds--toggle__text,
    .cds--text-area,
    .cds--number input[type='number'] {
      color: theme.get-var('text-secondary');
    }
  }

  &-answer {
    padding: $spacing-03 $spacing-05;
    border-radius: $spacing-03;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: justify;

    &__success {
      background-color: theme.get-var('notification-info-background');
    }

    &__error {
      color: theme.get-var('notification-error-border');
      background-color: theme.get-var('notification-error-background');
    }
  }
}
