@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';
@import '../base/colors';

$product-summary-gutter: 98px;

.ProductSummary {
  border-radius: $border-radius-02;
  box-shadow: $boxShadow-03;
  margin-left: $product-summary-gutter;
  min-width: 300px;
  border-left: 2px solid theme.get-var('border-interactive-primary');
  position: relative;
  background-color: theme.get-var('background');
  z-index: 1;
  .IconTitle {
    padding: $spacing-05;
    justify-content: center;
    margin-bottom: 0;
    border-bottom: 1px solid theme.get-var('border-tile');
  }
  .ProductSummary__content {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr;
    padding: $spacing-05 0;
    border-radius: $border-radius-01;
    .ProductSummary__block {
      text-align: center;
      position: relative;
      .ProductSummary__block-title {
        @include body-emphasis-03;
      }
      &:first-child {
        .ProductSummary__block-content {
          > :before {
            text-align: left;
            position: absolute;
            left: -$product-summary-gutter - 2px;
            padding: $spacing-03 $spacing-05;
            border: 1px solid theme.get-var('border-strong');
            border-radius: $border-radius-01 0 0 $border-radius-01;
            border-right: none;
            width: $product-summary-gutter;
            z-index: -1;
            box-shadow: $boxShadow-03;
            color: theme.get-var('text-primary');
          }
          > :nth-child(2):before {
            content: 'Current';
            @include body-02;
          }
          > :nth-child(3):before {
            content: 'Optimised';
            border-color: $secondary-500;
            @include body-emphasis-02;
          }
        }
      }
      &:not(:last-child):after {
        content: '';
        height: calc(100% + $spacing-05);
        width: 1px;
        background: theme.get-var('border-subtle');
        position: absolute;
        right: 0;
        top: -$spacing-05;
      }
      .ProductSummary__block-content {
        @include body-01;
        .ProductSummary__block-row {
          display: flex;
          padding: $spacing-05 0;
          margin: 0 $spacing-05;
          align-items: center;
          justify-content: space-evenly;

          > div {
            width: 100%;
            text-align: center;
          }
          &:first-child {
            height: $spacing-05;
          }
          &:nth-child(2) {
            border-bottom: 1px solid theme.get-var('border-subtle');
          }
          .ProductSummary__block-content--subtitle {
            @include body-03;
            padding: $spacing-02 + $spacing-01;
            padding-bottom: 0;
            color: theme.get-var('text-primary');
          }
        }
      }
    }
  }
}
