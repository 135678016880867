@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use './variables';

//Typography
@mixin heading-01 {
  font-size: $font-size-05;
  font-weight: $font-weight-05;
}

@mixin heading-02 {
  font-size: $font-size-06;
  font-weight: $font-weight-06;
}

@mixin heading-03 {
  font-size: $font-size-07;
  font-weight: $font-weight-05;
}

@mixin heading-04 {
  font-size: $font-size-08;
  font-weight: $font-weight-03;
}

@mixin heading-05 {
  font-size: $font-size-08;
  font-weight: $font-weight-05;
}

@mixin heading-06 {
  font-size: $font-size-10;
  font-weight: $font-weight-03;
}

@mixin heading-07 {
  font-size: $font-size-11;
  font-weight: $font-weight-05;
}
@mixin heading-08 {
  font-size: $font-size-14;
  font-weight: $font-weight-03;
}
@mixin heading-09 {
  font-size: $font-size-17;
  font-weight: $font-weight-03;
}
@mixin body-01 {
  font-size: $font-size-06;
  font-weight: $font-weight-04;
}
@mixin body-emphasis-01 {
  font-size: $font-size-06;
  font-weight: $font-weight-06;
}

@mixin body-02 {
  font-size: $font-size-05;
  font-weight: $font-weight-04;
}
@mixin body-emphasis-02 {
  font-size: $font-size-05;
  font-weight: $font-weight-06;
}
@mixin body-03 {
  font-size: $font-size-04;
  font-weight: $font-weight-04;
}
@mixin body-04 {
  font-size: $font-size-03;
  font-weight: $font-weight-03;
}
@mixin body-emphasis-03 {
  font-size: $font-size-04;
  font-weight: $font-weight-06;
}
@mixin helper-link-01 {
  font-size: $font-size-04;
  font-weight: $font-weight-05;
}
@mixin link-01 {
  font-size: $font-size-05;
  font-weight: $font-weight-04;
}
@mixin link-02 {
  font-size: $font-size-06;
  font-weight: $font-weight-04;
}
@mixin label-01 {
  font-size: $font-size-05;
  font-weight: $font-weight-04;
}
@mixin label-02 {
  font-size: $font-size-06;
  font-weight: $font-weight-04;
}
@mixin helper-text-01 {
  font-size: $font-size-04;
  font-weight: $font-weight-04;
}
