@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.GenAIPanel {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: $spacing-05;
  margin: -80px 0 $spacing-05;
  padding-right: $spacing-07;

  &__header {
    margin-bottom: $spacing-05;

    &-title {
      display: flex;
      align-items: center;
      font-size: $font-size-06;
      color: theme.get-var('text-button-primary');
      margin-bottom: $spacing-03;
      gap: $spacing-03;
    }

    &-text {
      font-size: $font-size-05;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;

    &-title {
      display: flex;
      align-items: center;
      font-size: $font-size-05;
      color: theme.get-var('text-button-primary');
      margin-bottom: $spacing-03;
      gap: $spacing-03;
    }

    &-action {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $spacing-03;
    }
  }

  &__button {
    text-align: left;
    cursor: pointer;
    border-radius: $border-radius-01;
    padding: $spacing-04;
    font-size: $font-size-05;
    line-height: 24px;
    border: 2px solid theme.get-var('main-button-tertiary');
    color: theme.get-var('text-primary');
    background: theme.get-var('main-button-tertiary');

    > svg {
      color: theme.get-var('text-button-secondary');
    }

    &:hover {
      background: theme.get-var('main-button-tertiary-hover');
      border: 2px solid theme.get-var('main-button-tertiary-hover');

      &:active {
        background: theme.get-var('main-button-tertiary-hover');
        border-color: theme.get-var('main-button-tertiary-hover');
      }
    }

    &:active,
    &:focus {
      box-shadow: none;
      background: theme.get-var('main-button-tertiary');
      border-color: theme.get-var('main-button-primary-active');
    }

    &:disabled {
      cursor: not-allowed;
      border-color: theme.get-var('background-disabled');
      background: theme.get-var('background-disabled');
      color: theme.get-var('text-on-color-disabled');

      > svg {
        color: theme.get-var('text-on-color-disabled');
      }

      &:active,
      &:hover,
      &:focus {
        background: theme.get-var('background-disabled');
        border-color: theme.get-var('background-disabled');
        outline: 0;
      }
    }

    &-back {
      cursor: pointer;
    }
  }

  &__message {
    display: flex;
    align-items: center;
    border-radius: $border-radius-02;
    background: theme.get-var('main-button-tertiary-hover');
    border: 2px solid theme.get-var('main-button-tertiary-hover');
    font-size: $font-size-05;
    padding: $spacing-03 $spacing-05;
    min-height: 48px;
  }

  &__answer {
    display: flex;
    color: theme.get-var('text-secondary');
    font-size: $font-size-05;
    white-space: pre-wrap;

    &-error {
      padding: $spacing-03 $spacing-05;
      color: theme.get-var('notification-error-border');
      background-color: theme.get-var('notification-error-background');
    }

    &-success {
      margin-top: $spacing-03;
    }

    &-icon {
      color: theme.get-var('icon-primary-bright');
      margin: $spacing-02 $spacing-03 0;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: $spacing-03;
    width: 100%;

    &-button {
      cursor: pointer;
      color: theme.get-var('text-button-neutral');
      display: inline-flex;
      align-items: center;
      gap: $spacing-03;
      padding: $spacing-04 $spacing-05;
      background: none;
      border: 2px solid transparent;
      border-radius: $border-radius-01;
      font-size: $font-size-05;

      &:hover {
        background-color: theme.get-var('main-button-ghost-neutral-hover');
      }

      &:focus {
        border: 2px solid theme.get-var('focus');
      }

      &.active {
        border-color: theme.get-var('focus');
      }
    }

    &-container {
      display: flex;
      gap: $spacing-05;
    }

    &-feedback {
      background-color: transparent;
      border: 0;
      padding: 0;
      cursor: pointer;

      &:hover {
        background-color: transparent;
      }
    }

    &-options {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: $spacing-04;
      border: 1px solid theme.get-var('border-subtle');
      border-radius: $border-radius-01;

      &-close {
        cursor: pointer;
        background-color: transparent;
        border: 0;
        line-height: 1;
        border-radius: $border-radius-01;
        padding: $spacing-02;

        &:hover {
          background-color: theme.get-var('background-hover');
        }
      }

      &-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: $spacing-04;
      }

      &-title {
        @include body-02;
        color: theme.get-var('text-secondary');
        display: flex;
        align-items: center;
        gap: $spacing-03;
      }

      &-text {
        @include body-02;
        color: theme.get-var('text-secondary');
        margin: $spacing-05 0;
      }
    }
  }
}
