@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';

.ChartContainer__header {
  display: flex;
  padding-bottom: $spacing-06;
}

.chart-container-actions {
  flex-grow: 1;
  gap: 6px;
  display: flex;
  justify-content: flex-end;
  .exporting-file {
    justify-content: flex-end;
  }
  .ChartContainer__chart-options-menu.cds--overflow-menu {
    svg {
      fill: theme.get-var('text-primary');
    }
  }
}

.ChartContainer__chart-options-content.cds--overflow-menu-options--open {
  padding: $spacing-04;
  gap: $spacing-05;
  min-width: 210px;
  .ChartContainer__chart-options-dropdown {
    width: 100%;
    margin: 0;
  }
  &.cds--overflow-menu-options .cds--form-item.cds--checkbox-wrapper {
    padding: 0 $spacing-04 0 0;
  }
}

.highcharts-tooltip.chart-tooltip {
  pointer-events: auto !important;
  .tooltip-container {
    padding-right: $spacing-03;
    scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
    scrollbar-width: thin;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: $border-radius-01;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  > span {
    background: theme.get-var('background');
    border-radius: $border-radius-01;
    box-shadow: $boxShadow-02;
    padding: $spacing-03;
  }
  td {
    font-size: $font-size-04;
  }
}

.highcharts-axis-title .axis-edit-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .axis-edit-icon {
    margin-right: $spacing-03;
    width: 22px;
    height: 22px;
    background-color: theme.get-var('main-button-tertiary');
    border-radius: $border-radius-01;
    background-image: url('../../assets/icons/edit.svg');
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}
