@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';
@import '../base/colors';

.SummaryCards {
  gap: $spacing-05;
  .SummaryCard {
    background: theme.get-var('background');
    border-radius: $border-radius-02;
    box-shadow: $boxShadow-03;
    border-left: 2px solid theme.get-var('border-interactive-primary');
    .IconTitle {
      padding: $spacing-05;
      justify-content: center;
      margin-bottom: 0;
      border-bottom: 1px solid theme.get-var('border-tile');
    }

    .SummaryCard--content {
      padding: $spacing-05;
      .SummaryCard--content-title {
        text-align: center;
        @include body-emphasis-03;
      }
      .SummaryCard--content-value {
        text-align: center;
        padding: $spacing-07;
        color: theme.get-var('text-secondary');
        border-bottom: 1px solid theme.get-var('border-subtle');
        @include body-01;
      }
      .SummaryCard--content-footer {
        display: flex;
        justify-content: space-evenly;
        padding: $spacing-05 $spacing-05 0 $spacing-05;
        .SummaryCard--content-footer-value {
          @include body-emphasis-01;
          padding: $spacing-03 $spacing-05;
          border-radius: $border-radius-02;
          color: theme.get-var('text-secondary');
          &__neutral {
            background-color: theme.get-var('background-active');
          }
          &__positive {
            background-color: theme.get-var('background-success');
            color: theme.get-var('text-success');
          }
          &__negative {
            background-color: theme.get-var('background-error');
            color: theme.get-var('text-error');
          }
        }
      }
    }
  }
}
