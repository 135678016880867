@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@use '../base/typography' as *;
@use '../base/colors' as *;

.Module__container-wrapper {
  width: 100%;
}
.Module__container {
  margin-top: $spacing-07;
  a {
    text-decoration: none;
  }
}
.Module__image-container {
  padding: $spacing-06 $spacing-09;
  height: 178px;
}

.Module__box {
  height: 242px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: left;
  background: #ffffff;
  border: 1px solid theme.get-var('border-subtle');
  outline: 1px solid transparent;
  box-shadow: $boxShadow-01;
  border-radius: $border-radius-02;
  text-decoration: none;
  position: relative;
  .Module__badge {
    position: absolute;
    top: 0;
    width: 71px;
  }
  &:hover {
    box-shadow: $boxShadow-02;
    cursor: pointer;
    transition: all 0.11s;
    border: 1px solid transparent;
    outline: 2px solid theme.get-var('layer-hover-02');
    background-color: theme.get-var('layer-selected-hover-02');
    .Module__image-container {
      transform: scale(1.04);
      transition: transform 0.5s;
    }
  }
  &--image {
    border-radius: $border-radius-02 $border-radius-02 0 0;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
  &--title {
    color: theme.get-var('background-primary');
  }
  &--text {
    @include body-02;
    margin-top: $spacing-03;
    color: theme.get-var('text-secondary');
  }
  &--badge {
    position: relative;
    top: -24px;
    left: -48px;
    width: 71px;
  }
  &--content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-05;
    letter-spacing: -0.5px;
    min-height: 64px;

    h5 {
      margin-right: $spacing-03;
      text-decoration: none;
    }
  }
  &--info {
    color: theme.get-var('icon-neutral');
    opacity: 0.5;
  }
}
.maintenance {
  position: relative;
  filter: brightness(0.95);
  opacity: 50%;
}

.notification {
  position: absolute;
  z-index: 2;
}

.Modules__page-description {
  color: theme.get-var('text-secondary');
  margin-bottom: $spacing-05;
  @include body-02;
}

.Modules__loading {
  margin-top: $spacing-08;
}
