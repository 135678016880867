@import '../abstracts/variables';
@import '../abstracts/mixins';

.heading-01 {
  @include heading-01;
}
.heading-02 {
  @include heading-02;
}
.heading-03 {
  @include heading-03;
}
.heading-04 {
  @include heading-04;
}
.heading-05 {
  @include heading-05;
}
.heading-06 {
  @include heading-06;
}
.heading-07 {
  @include heading-07;
}
.heading-08 {
  @include heading-08;
}
.heading-09 {
  @include heading-09;
}
.body-01 {
  @include body-01;
}
.body-emphasis-01 {
  @include body-emphasis-01;
}
.body-02 {
  @include body-02;
}
.body-emphasis-02 {
  @include body-emphasis-02;
}
.body-03 {
  @include body-03;
}
.body-emphasis-03 {
  @include body-emphasis-03;
}
.helper-link-01 {
  @include helper-link-01;
}
.link-01 {
  @include link-01;
}
.link-02 {
  @include link-02;
}
.label-01 {
  @include label-01;
}
.label-02 {
  @include label-02;
}
.helper-text-01 {
  @include helper-text-01;
}
