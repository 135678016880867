@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/mixins';
@import '../abstracts/variables';

.rdrCalendarWrapper {
  @include body-02;
  box-shadow: $boxShadow-02;
  border-radius: $border-radius-01;
  max-height: 480px;
  max-width: 385px;
  margin: $spacing-05;
  border: 0.5px solid theme.get-var('border-subtle');
}
.rdrDateDisplayItem {
  background-color: theme.get-var('background');
  border: 1px solid transparent;
  border-radius: $border-radius-01;
}

.rdrDateDisplayItem input {
  @include body-02;
  padding-right: 70px;
  cursor: pointer;
  height: 34px;
  border: 0px;
  width: 100%;
  color: theme.get-var('text-secondary');
  background: url('../../assets/icons/calendar.svg') no-repeat 160px;
}

.rdrDateDisplayItemActive {
  border: 2px solid theme.get-var('focus');
  border-radius: $border-radius-01;
}

.rdrDateDisplayItemActive input {
  border-color: theme.get-var('icon-secondary');
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 50px;
}

.rdrMonthAndYearPickers {
  font-weight: 600;
}

.rdrMonthAndYearPickers select {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  padding: $spacing-03;
  border: none;
  border-radius: $border-radius-01;
  background: theme.get-var('main-button-tertiary');
  margin-right: 5px;
  outline: 0;
  color: theme.get-var('text-primary');
  @include body-emphasis-02;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
}

.rdrMonthAndYearPickers select:hover {
  background-color: theme.get-var('main-button-tertiary-hover');
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: $border-radius-01;
  background: transparent;
  &:hover {
    background-color: theme.get-var('layer-accent-hover-03');
  }
}
.rdrDateDisplayItem + .rdrDateDisplayItem {
  margin-left: 0;
}

.rdrNextPrevButton i {
  display: block;
  width: 0;
  height: 0;
  padding: 0;
  text-align: center;
  border-style: solid;
  margin: auto;
  transform: translate(-3px, 0px);
}

.rdrPprevButton i {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-top: 1px solid theme.get-var('icon-primary');
  border-right: 1px solid theme.get-var('icon-primary');
  transform: rotate(-135deg);
}

.rdrNextButton i {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-top: 1px solid theme.get-var('icon-primary');
  border-right: 1px solid theme.get-var('icon-primary');
  transform: rotate(45deg);
}

.rdrWeekDays {
  @include body-emphasis-02;
  background: theme.get-var('background-hover');
  height: 40px;
}

.rdrMonth {
  padding: 0 0.833em 1.666em 0.833em;
}

.rdrMonth .rdrWeekDays {
  padding: 0;
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
  display: none;
}

.rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: theme.get-var('text-secondary');
  padding-top: 2px;
  font-size: 14px;
}

.rdrDay {
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0px;
  line-height: 3em;
  text-align: center;
  color: theme.get-var('text-secondary');
}

.rdrDay:focus {
  outline: 0;
}

.rdrDayNumber {
  outline: 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 500;
}

.rdrDayToday .rdrDayNumber span:after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 8px;
  height: 4px;
  background: theme.get-var('text-secondary');
  margin-bottom: -2px;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  color: theme.get-var('text-secondary');
  @include body-02;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: theme.get-var('text-secondary');
  @include body-02;
}
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
  color: theme.get-var('text-secondary');
  @include body-02;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background: theme.get-var('text-secondary');
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0px;
  height: 40px;
}

.rdrSelected {
  left: 2px;
  right: 2px;
}

.rdrInRange {
  background: theme.get-var('table-header-hover');
}

.rdrStartEdge {
  background: theme.get-var('layer-hover-02');
}

.rdrEndEdge {
  background: theme.get-var('layer-hover-02');
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  height: 40px;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  left: 0px;
  height: 40px;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-right-width: 1px;
  border-left-width: 1px;
  right: 0px;
  height: 40px;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  background: transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;
  border: 0px solid theme.get-var('icon-primary');
  z-index: 1;
}

.rdrDayStartPreview {
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  height: 40px;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  height: 40px;
}

.rdrDayEndPreview {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  height: 40px;
}

.rdrDefinedRangesWrapper {
  font-size: 12px;
  width: 226px;
  border-right: solid 1px theme.get-var('text-secondary');
  background: theme.get-var('background');
}

.rdrStaticRange {
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid theme.get-var('text-secondary');
  background: theme.get-var('text-secondary');
  padding: 0;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: theme.get-var('text-secondary');
}

.rdrStaticRangeLabel {
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}

.rdrInputRanges {
  padding: 10px 0;
}

.rdrInputRange {
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput {
  width: 40px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px theme.get-var('text-secondary');
  color: theme.get-var('icon-primary');
  margin-right: 10px;
}

.rdrInputRangeInput:focus,
.rdrInputRangeInput:hover {
  border-color: theme.get-var('text-secondary');
  color: theme.get-var('text-secondary');
  outline: 0;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper)
  .rdrDayHovered
  .rdrDayNumber:after {
  content: '';
  border: '1px solid transparent';
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.rdrDayPassive {
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span {
  color: theme.get-var('text-disabled');
}

.rdrDayPassive .rdrInRange,
.rdrDayPassive .rdrStartEdge,
.rdrDayPassive .rdrEndEdge,
.rdrDayPassive .rdrSelected,
.rdrDayPassive .rdrDayStartPreview,
.rdrDayPassive .rdrDayInPreview,
.rdrDayPassive .rdrDayEndPreview {
  display: none;
}

.rdrDayDisabled {
  background-color: theme.get-var('background');
}

.rdrDayDisabled .rdrDayNumber span {
  color: theme.get-var('text-disabled');
}

.rdrDayDisabled .rdrInRange,
.rdrDayDisabled .rdrStartEdge,
.rdrDayDisabled .rdrEndEdge,
.rdrDayDisabled .rdrSelected,
.rdrDayDisabled .rdrDayStartPreview,
.rdrDayDisabled .rdrDayInPreview,
.rdrDayDisabled .rdrDayEndPreview {
  filter: grayscale(100%) opacity(60%);
}

.rdrMonthName {
  text-align: left;
  font-weight: 600;
  color: theme.get-var('icon-primary');
  padding: 0.833em;
}
