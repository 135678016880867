@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

#oneviu-modal {
  .cds--modal-content {
    margin-bottom: 0;
  }
  .cds--text-input,
  .cds--number input[type='number'] {
    background-color: theme.get-var('background');
  }
}
.modal-body {
  border-radius: $border-radius-01;

  .modal-close-icon {
    cursor: pointer;
    z-index: 1;
    display: flex;
    position: absolute;
    top: 24px;
    right: 16px;
  }
  .cds--modal-close:hover {
    background-color: transparent;
  }
}
.modal-body > .modal-img-container {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.modal-body > .modal-body-container {
  align-items: center;
  background-color: theme.get-var('background');
  border-radius: $border-radius-01;
  display: flex;
  flex-direction: column;
  padding: 0 $spacing-05 $spacing-08 $spacing-05;
  gap: $spacing-06;
}

.modal-body-text {
  text-align: center;
  white-space: pre-wrap;
  .modal-header {
    @include heading-05;
    margin-bottom: $spacing-06;
  }
  .modal-subheader {
    @include body-02;
    line-height: 1.5rem;
    padding: 0 $spacing-03;
  }
}

.modal-actions {
  display: flex;
  gap: $spacing-05;
}
