@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;

.refresh-wrapper {
  display: flex;
  justify-content: flex-end;
}

.refresh-component {
  float: right;
  &.subtle {
    color: theme.get-var('icon-primary');
    font-size: 10px;
    padding: $spacing-03 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    cursor: pointer;
  }
}

.refresh-message {
  margin-left: $spacing-03;
}
