@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@use '@carbon/colors' as carbonColor;
@import '../base/colors';
@import '../abstracts/variables';
@import '../abstracts/mixins';

.UsersPanel__invite-user-buttons {
  display: flex;
  justify-content: flex-end;
  gap: $spacing-05;
  .cds--btn {
    gap: $spacing-03;
  }
}

.UsersPanel__container {
  margin-top: $spacing-06;
  margin-bottom: $spacing-06;
  padding-bottom: $spacing-01;
  border-radius: $border-radius-01;
  background-color: theme.get-var('background');
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);

  .UsersPanel__no-data-placeholder {
    padding: $spacing-04 0;
  }
  .UsersPanel__filters {
    display: flex;
    gap: $spacing-03;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-05 $spacing-06;
    .cds--search {
      max-width: 360px;
    }
    .UsersPanel__filter-button {
      width: 100px;
    }
  }
  .FiltersPopover__options-list {
    margin-top: 0;
    padding: 0 $spacing-06;
    margin-bottom: $spacing-03;
  }
  .UsersTableRow__status {
    @include body-02;
    white-space: nowrap;
    height: 24px;
    cursor: auto;
    &--active {
      background-color: theme.get-var('background-success');
      color: theme.get-var('text-success');
    }
    &--pending {
      background-color: theme.get-var('background-error');
      color: theme.get-var('text-error');
    }
    &--disabled {
      background-color: theme.get-var('background-disabled');
      color: theme.get-var('text-helper');
    }
  }
}

.UsersTable__container {
  th {
    border-left: 1px solid theme.get-var('border-subtle');
    border-right: 1px solid theme.get-var('border-subtle');
    &.header-checkbox {
      border: none;
    }
    &:nth-of-type(2) {
      border-left: none;
    }
  }
  td {
    border: 1px solid theme.get-var('border-subtle');
    &.cds--table-column-checkbox {
      border-right: none;
    }
    &:nth-of-type(2) {
      border-left: none;
    }
  }
  th,
  td,
  th.header-checkbox {
    &:first-of-type {
      position: sticky;
      left: 0;
      z-index: 1;
    }
    &:nth-of-type(2) {
      position: sticky;
      left: 40px;
      z-index: 1;
      &::after {
        border-right: 2px solid var(--cds-table-header-active);
        position: absolute;
        right: -1px;
        top: 0;
        bottom: 0;
        content: '';
      }
    }
    .cds--table-column-checkbox {
      width: 40px;
    }
  }

  .UsersTableRow__name-cell {
    display: flex;
    min-width: 220px;
    padding: $spacing-04 0;
    .gravatar-name img {
      padding: 0 $spacing-04 0 0;
    }
    .UsersTableRow__name-cell-info {
      div:last-of-type {
        font-size: 10px;
        margin-top: $spacing-03;
      }
    }
  }

  .UsersTableRow__user-type-cell {
    text-transform: capitalize;
    align-items: center;

    .UsersTableRow__user-type-cell-title {
      display: flex;
      gap: $spacing-03;
      align-items: center;
    }

    .UsersTableRow__user-type-cell-subtitle {
      margin-top: $spacing-03;
      @include body-03;
    }

    .UsersTableRow__user-type-cell-icon {
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $border-radius-01;
      background-color: theme.get-var('layer-02');
      color: theme.get-var('icon-primary-bright');

      &--customer {
        background-color: theme.get-var('layer-02');
        color: theme.get-var('icon-primary-bright');
      }
      &--support {
        background-color: #fff2e8;
        color: #ba4e00;
      }
      &--supplier {
        background-color: #defbe6;
        color: #0e6027;
      }
    }
  }

  .UsersTableRow__user-role-cell {
    display: flex;
    gap: $spacing-03;
    > div {
      white-space: nowrap;
    }
  }
  .UsersTableRow__loading-indicator {
    display: inline-flex;
    align-items: center;
  }
  .UsersTableRow__status-cell {
    width: 90px;
  }
  .UsersTableRow__actions-cell {
    display: flex;
    justify-content: center;
    gap: $spacing-03;
    align-items: center;
    svg {
      cursor: pointer;
    }
    .UsersTableRow__disable-action-icon.cds--tooltip svg {
      width: 22px;
      height: 22px;
    }
  }
  .UsersTableRow__actions-cell--disabled {
    color: theme.get-var('text-disabled');
    svg {
      cursor: not-allowed;
    }
  }
  .cds--action-list .cds--btn {
    gap: $spacing-03;
  }
}

.UsersTableRow__delete-user-modal .cds--modal-container {
  width: auto;
}

.AllUsers__radio-button.cds--radio-button-wrapper {
  display: flex;
  align-items: center;
  .cds--radio-button__appearance {
    margin-right: $spacing-06;
  }
  .cds--radio-button__label {
    align-items: center;
    font-size: $font-size-05;
  }
}

.BannerList__container {
  display: flex;
  flex-direction: column;
  gap: $spacing-03;
  padding: $spacing-04 0;
  .cds--popover-container {
    width: fit-content;
  }
}

.BannerPopover__wrapper {
  width: 100%;
  .cds--overflow-menu {
    width: fit-content;
    height: fit-content;
  }
  .BannerPopover__target {
    user-select: none;
    word-break: normal;
    border-radius: $border-radius-02;
    margin: 0;
    flex-direction: row-reverse;
    gap: $spacing-03;
    .cds--tag__custom-icon {
      margin: 0;
    }
    &.BannerPopover__target--open {
      box-shadow: $boxShadow-03;
      border-width: 1px;
    }
  }
}

.BannerPopover__content {
  position: absolute;
  background-color: #fff;
  z-index: 3;
  width: 210px;
  max-height: 180px;
  overflow-y: auto;
  box-shadow: $boxShadow-03;
  border-radius: $border-radius-01;
  .BannerPopover__content-item {
    @include body-02;
    line-height: 18px;
    color: theme.get-var('text-secondary');
    padding: $spacing-04 $spacing-05;
    border-bottom: 1px solid theme.get-var('border-subtle');
  }
  .BannerPopover__content-header {
    @include body-03;
    color: theme.get-var('text-helper');
    padding: $spacing-04 $spacing-05;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: theme.get-var('background');
    svg {
      cursor: pointer;
    }
  }
}
.BannerPopover__close-icon {
  transition: background-color 0.1s;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  &:hover {
    background-color: theme.get-var('background-hover');
  }
}

.InviteTeamMemberModal__container {
  .cds--modal-container {
    width: 60%;
    height: 90%;
    max-height: 760px;
    max-width: 1000px;
  }
  .cds--modal-content {
    padding: 0 $spacing-07;
  }
  .cds--modal-header {
    padding: $spacing-06 $spacing-07 0;
    .cds--modal-close {
      top: $spacing-06;
      right: $spacing-07;
      width: 24px;
      height: 24px;
      padding: 0;
    }
  }
  .InviteTeamMemberModal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid theme.get-var('divider');
    padding-bottom: $spacing-03;
    .InviteTeamMemberModal__header-title {
      display: flex;
      gap: $spacing-05;
      align-items: center;
      @include heading-03;
    }
    .InviteTeamMemberModal__close-icon {
      cursor: pointer;
    }
  }
  .InviteTeamMemberModal__content {
    display: flex;
    flex-direction: column;
    gap: $spacing-06;
    padding-top: $spacing-06;
    padding-bottom: 60px;
    height: 100%;
    overflow: auto;
  }
  .InviteTeamMemberModal__text-input {
    flex-grow: 0;
  }
}

.LabelWithTooltip__container.cds--inline-notification {
  max-width: 435px;
}

.LabelWithTooltip__target {
  cursor: pointer;
}

.InviteTeamMemberModal__dropdown-placeholder {
  color: theme.get-var('text-placeholder');
}

.BulkUserInviteModal__container {
  .cds--modal-container {
    padding: $spacing-06 $spacing-07;
    width: 930px;
  }
  .cds--modal-header {
    padding: 0;
  }
  .cds--modal-content {
    padding: $spacing-03 0 0;
  }
  .BulkUserInviteModal__header {
    border-bottom: 1px solid theme.get-var('divider');
    padding-bottom: $spacing-02;
    .BulkUserInviteModal__header-title {
      display: flex;
      gap: $spacing-05;
      align-items: center;
      margin-bottom: $spacing-03;
      padding-top: $spacing-03;
      @include heading-03;
    }
    .BulkUserInviteModal__header-description {
      @include body-02;
      line-height: 24px;
      color: theme.get-var('text-secondary');
    }
  }
  .cds--btn.BulkUserInviteModal__view-user-groups {
    color: theme.get-var('text-button-primary');
  }
  .BulkUserInviteModal__file-info {
    padding: $spacing-04 0 $spacing-05;
    p {
      line-height: 24px;
    }
  }
  .BulkUserInviteModal__drag-and-drop-info {
    @include body-02;
    &--highlighted {
      color: theme.get-var('link-visited');
    }
  }
  .cds--file {
    .BulkUserInviteModal__drag-and-drop-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: $spacing-07;
    }

    .BulkUserInviteModal__drag-and-drop-button {
      position: relative;
      width: 28px;
      height: 28px;
      border-radius: $border-radius-01;
      margin-bottom: $spacing-03;
      background-color: theme.get-var('highlight');
      color: theme.get-var('icon-primary-light');
      padding: $spacing-02;
      &:hover {
        background-color: theme.get-var('table-header-active');
      }
    }
    .cds--file-browse-btn {
      max-width: 100%;
      &:hover {
        text-decoration: none;
        border-radius: $border-radius-01;
      }
    }
    .cds--file__drop-container {
      height: 158px;
      width: 100%;
      border-radius: $border-radius-02;
      border-color: theme.get-var('border-interactive-primary');
    }
  }
  .cds--file__selected-file {
    min-width: 100%;
    border-radius: $border-radius-02;
    justify-content: space-between;
    margin-top: $spacing-03;
  }
  .BulkUserInviteModal__footer-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .cds--btn {
      max-width: fit-content;
      height: auto;
    }
  }
}

.BulkUserInviteModal__container,
.InviteTeamMemberModal__container {
  .cds--modal-footer {
    .cds--btn {
      gap: $spacing-05;
    }
    .cds--inline-loading {
      width: fit-content;
      min-height: fit-content;
    }
  }
  .cds--form-requirement__title {
    white-space: pre-wrap;
  }
}

.UserGroupsTable {
  padding-bottom: $spacing-07;
  .UserGroupsTable__header {
    display: flex;
    gap: $spacing-04;
    align-items: center;
    margin-bottom: $spacing-06;
  }
  .UserGroupsTable__filters {
    display: flex;
    gap: $spacing-04;
    margin-bottom: $spacing-06;
    .UserGroupsTable__filters-search {
      max-width: 360px;
      .cds--search-input {
        border-color: theme.get-var('border-subtle');
      }
    }
    .UserGroupsTable__filters-dropdown {
      margin: 0;
    }
  }
  .data-table-container {
    border: 1px solid theme.get-var('border-subtle');
    border-bottom: none;
    border-radius: $border-radius-01;
  }
  .UserGroupsTable__table {
    thead tr th {
      &:first-of-type {
        border-top-left-radius: $border-radius-01;
        .cds--table-sort {
          border-top-left-radius: $border-radius-01;
        }
      }
      &:last-of-type {
        border-top-right-radius: $border-radius-01;
        .cds--table-sort {
          border-top-right-radius: $border-radius-01;
        }
      }
    }
    th:not(:first-of-type),
    td:not(:first-of-type) {
      border-left: 1px solid theme.get-var('border-subtle');
    }
  }
  .cds--pagination {
    border: 1px solid theme.get-var('border-subtle');
    border-top: 0;
    border-radius: 0 0 $border-radius-01 $border-radius-01;
  }
}

.InviteTeamMemberModal__container,
.EditUserModal__container {
  .cds--dropdown__wrapper,
  .cds--multi-select__wrapper .cds--multi-select {
    margin: 0;
    .cds--list-box__menu {
      max-height: 120px;
      max-width: 100%;
      min-width: 100%;
    }
  }
  .InviteTeamMemberModal__dropdown-container.cds--dropdown__wrapper {
    width: 50%;
  }
  .InviteTeamMemberModal__banner-row {
    display: grid;
    grid-template-columns: 50% calc(50% - 40px) 40px;
  }
  .InviteTeamMemberModal__multi-select-container {
    padding-left: $spacing-06;
    .cds--multi-select__wrapper {
      width: 90%;
      &:last-child {
        width: 100%;
      }
    }
  }
  &.InviteTeamMemberModal__content--with-bottom-space {
    padding-bottom: 125px;
  }
  .InviteTeamMemberModal__delete-banner-button {
    align-self: flex-start;
    padding: $spacing-03;
    min-height: 16px;
    margin-left: $spacing-03;
    margin-top: $spacing-07;
  }
  .cds--label {
    color: theme.get-var('text-primary');
  }
  .PersonalDetailsStep__read-only-value {
    @include body-02;
    margin-top: $spacing-03;
  }
  .InviteTeamMemberModal__buttons {
    display: flex;
    justify-content: flex-end;
    gap: $spacing-04;
    padding: 0 $spacing-07 $spacing-06;
    .cds--btn.InviteTeamMemberModal__save-button {
      width: 144px;
      justify-content: center;
    }
  }
  .cds--modal-footer .cds--btn {
    max-width: unset;
    width: auto;
    flex: unset;
    height: 42px;
    gap: $spacing-05;
    &:not(:focus) {
      box-shadow: unset;
    }
  }
  .UserAccessStep__notification.cds--inline-notification {
    max-width: 300px;
    .cds--inline-notification__title {
      @include body-02;
    }
  }
}

.EditUserModal__container {
  .cds--modal-container {
    min-height: 620px;
    width: 815px;
  }
  .EditUserModal__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-03;
    padding-top: $spacing-07;
  }
  .EditUserModal__content {
    display: flex;
    flex-direction: column;
    gap: $spacing-06;
    padding-bottom: 50px;
    &--with-bottom-space {
      padding-bottom: 120px;
    }
  }
  .EditUserModal__tabs-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
