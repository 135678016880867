@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.ReportCopyModal {
  &__title {
    @include body-01;
    color: theme.get-var('text-primary');
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: $spacing-04;
    padding-top: $spacing-03;
  }

  .cds--modal {
    &-container {
      border-radius: $border-radius-01;
      padding: $spacing-06;
    }

    &-header {
      padding: 0;

      .cds--modal-close {
        display: none;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: $spacing-05;
      padding: $spacing-03 0;
      color: theme.get-var('text-secondary');

      > p {
        padding: 0;
      }
    }
  }
}
