@use '@carbon/styles/scss/utilities/custom-property' as theme;

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Inter', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

#root {
  height: 100%;
  width: 100%;
}
