@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.DataVisualDropdowns__wrapper {
  display: flex;
  justify-content: space-between;
}

.visual-dropdowns {
  padding-bottom: $spacing-06;
  display: flex;
  flex-wrap: wrap;
  row-gap: $spacing-02;

  &.shared {
    padding: $spacing-04 0 $spacing-06;
    gap: $spacing-05;
    align-items: center;
    .cds--dropdown__wrapper,
    .visual-dropdowns__one-option--shared {
      margin: 0;
    }
  }

  &--switchers {
    gap: $spacing-03;
  }

  .visual-dropdowns__one-option,
  .visual-dropdowns__one-option--shared {
    display: flex;
    flex-direction: column;
    margin: 0 $spacing-03;

    > div {
      @include body-02;
      display: flex;
      height: 32px;
      align-items: center;
      color: theme.get-var('text-secondary');
      border: 1px solid theme.get-var('border-subtle');
      border-radius: $border-radius-01;
      background: theme.get-var('background');
      padding: 0 $spacing-05;
    }

    .cds--label {
      margin-bottom: $spacing-03;
    }

    &--shared {
      > div {
        height: 40px;
      }
    }
    &--md div {
      height: 40px;
    }
  }
}

.DataVisual__dropdowns-wrapper {
  .visual-dropdowns {
    gap: $spacing-05;
    .cds--dropdown__wrapper {
      margin: 0;
    }
  }
}

.kpi-tree-chart-container {
  position: relative;
  box-shadow: 0px 6px 16px #00000026;
  border-radius: 4px;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.ReportMetaData {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  .ReportMetaData__title {
    @include heading-05;
    padding: $spacing-03;
    margin-bottom: $spacing-05;
    border-bottom: 1px solid theme.get-var('border-subtle');
  }
  .ReportMetaData__metadata {
    border-radius: $border-radius-01;
    border: 1px solid theme.get-var('border-subtle');
    margin-bottom: $spacing-05;
  }
  .ReportMetaData__content {
    flex-grow: 1;

    .ReportMetaData__report-name {
      padding: 0 $spacing-03;
      label {
        @include body-02;
        line-height: 24px;
      }
    }

    .ReportMetaData__user-name {
      .ReportMetaData__user-name-title {
        @include body-emphasis-02;
      }
      @include body-emphasis-02;
      padding: $spacing-03;
      label {
        @include body-02;
      }
    }

    .ReportMetaData__summary-section {
      padding: 0 $spacing-05;
      margin-bottom: $spacing-04;
    }
  }

  .ReportMetaData__actions {
    position: sticky;
    bottom: 0;
    padding: $spacing-05 0;
    border-top: 1px solid theme.get-var('border-subtle-01');
    background: theme.get-var('background');
    gap: $spacing-05;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
  }
}

.header-checkbox {
  .cds--checkbox-label::before {
    background-color: theme.get-var('background');
    border-color: theme.get-var('text-primary');
  }

  .cds--checkbox {
    &:indeterminate {
      + {
        .cds--checkbox-label {
          &::before {
            background-color: theme.get-var('background');
          }
          &::after {
            border-bottom: 2px solid theme.get-var('text-primary');
          }
        }
      }
    }
    &:checked {
      + {
        .cds--checkbox-label {
          &::before {
            background-color: theme.get-var('background');
          }
          &::after {
            border-bottom: 2px solid theme.get-var('text-primary');
            border-left: 2px solid theme.get-var('text-primary');
          }
        }
      }
    }
    &:focus {
      outline: none;
      + {
        .cds--checkbox-label {
          &::before {
            outline: none;
          }
        }
      }
    }
  }
}
.table-row-checkbox {
  .cds--checkbox {
    &:focus {
      outline: none;
      + {
        .cds--checkbox-label {
          &::before {
            outline: none;
          }
        }
      }
    }
    &:focus-visible {
      outline: 2px solid theme.get-var('focus');
      + {
        .cds--checkbox-label {
          &::before {
            outline: 2px solid theme.get-var('focus');
          }
        }
      }
    }
  }
}

.toolbar-buttons {
  .cds--btn.cds--btn--primary {
    background-color: theme.get-var('table-header-main');
    color: theme.get-var('text-primary');
    &:hover {
      background-color: theme.get-var('table-header-hover');
    }
  }
  .cds--action-list .cds--btn--primary:focus {
    outline: none;
  }
  .cds--btn--primary {
    background-color: theme.get-var('table-header-main');
    @include body-emphasis-02;
    color: theme.get-var('text-primary');
    &::before {
      background-color: theme.get-var('text-primary');
    }
    &:hover {
      background-color: theme.get-var('table-header-hover');
      color: theme.get-var('text-primary');
    }
    &:focus {
      border-color: transparent;
      box-shadow: none;
      outline: none;
    }
    &.selected {
      background-color: theme.get-var('button-hover');
    }
  }
}

#dendrogram {
  overflow: hidden;
  padding-left: $spacing-05;
}

.venn-container {
  width: 640px;
  height: 100%;
  margin: auto;
  padding-bottom: $spacing-09;
  .venn-chart {
    display: grid;
    grid-template-columns: repeat(10, 64px);
    grid-template-rows: repeat(10, 50px);
    .venn-rectangle {
      border-radius: 4px;
      opacity: 0.5;
    }
    .venn-section {
      opacity: 1;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .venn-legend {
    display: flex;
    justify-content: center;
    gap: $spacing-07;
  }
}

.legend-item {
  display: flex;
  .legend-dot {
    align-self: flex-start;
    height: $legend-dot-height;
    width: $legend-dot-height;
    border-radius: $legend-dot-border-radius;
    opacity: 0.5;
    margin-right: $spacing-03;
    flex-shrink: 0;
  }
}

.report-details-container {
  display: flex;
  align-items: center;
  gap: 6px;
  > h3 {
    margin-right: $spacing-02;
  }
  .report-action-button {
    &:hover {
      background-color: theme.get-var('background-active');
    }
    svg {
      fill: theme.get-var('main-button-primary-hover');
    }
    border: 1px solid theme.get-var('main-button-primary-hover');
  }
  .cds--btn.cds--btn--secondary {
    padding: 6px;
  }
}

.report-controls {
  display: flex;
  margin: $spacing-05 0 $spacing-04;
  &.no-switcher {
    justify-content: flex-end;
    margin-top: 0;
  }
}

.dendrogram-container {
  overflow-x: hidden;
  height: 100%;
}

.dendro-grid-cotainer {
  display: grid;
  grid-template-columns: 400px 1fr;
  overflow-y: hidden;
}

.cds--data-table {
  th:last-of-type {
    background-color: theme.get-var('palette-primary-600');
    min-height: $spacing-09;
  }

  .sticky-cell {
    position: sticky;
    border-collapse: separate;
    z-index: 1;
    white-space: nowrap;
  }
  .highlighted {
    background-color: theme.get-var('background-selected');
    .table-cell {
      font-weight: 600;
    }
  }

  .shadow-cell::after {
    border-right: 2px solid theme.get-var('table-header-active');
    position: absolute;
    right: -1px;
    top: 0;
    bottom: 0;
    content: '';
  }
}

.table-header {
  display: table-cell;
  position: relative;
  th {
    color: theme.get-var('field-01');
  }
  .sticky {
    position: sticky;
    z-index: 2;
  }
  .cds--table-sort {
    &.resizing {
      pointer-events: none;
    }
  }

  &:focus {
    .header-resize-handle {
      border-color: theme.get-var('focus');
    }
  }
  .header-resize-handle {
    border-right: 2px solid theme.get-var('table-header-active');
    cursor: col-resize;
    width: 4px;
    position: absolute;
    right: 0;
    top: 0;
    height: 48px;
    user-select: none;
    &.resizing {
      height: 1200px;
      border-color: theme.get-var('focus');
      &:hover {
        border-color: theme.get-var('focus');
      }
    }
    &:hover {
      border-color: theme.get-var('focus');
    }
  }
}

.scatter-summary {
  margin-bottom: $spacing-07;
}

.statuses-container {
  display: flex;
  flex-wrap: wrap;
}

.visual-container {
  margin: $spacing-04 0;
  box-shadow: $boxShadow-01;
  background-color: theme.get-var('background');
  border-radius: $border-radius-01;
  padding: 20px;
  &.no-background {
    box-shadow: none;
    background-color: transparent;
    margin-top: 0;
    padding: 0;
  }
  &.homepage {
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    .ChartContainer__header {
      padding-top: $spacing-05;
    }

    .cds--subgrid > div {
      border-top: 1px solid theme.get-var('divider');
      &:first-of-type {
        border-top: none;
      }
    }
  }
}

.fullscreen-container {
  &::backdrop {
    background-color: #fff;
  }
}

.fullscreen-true {
  display: flex;
  flex-direction: column;
  > .cds--subgrid {
    flex-grow: 1;
  }
  .visual-dropdowns.shared {
    margin: 0 $spacing-05;
  }
  .visual-container {
    display: grid;
    grid-template-rows: min-content 1fr;
    height: 100%;
    margin-top: 0;
    .chart-container {
      align-self: center;
    }
  }
  .cds--popover--top {
    .cds--popover-caret,
    .cds--popover-content {
      left: auto;
    }
  }
}

.kpi-tree-controls {
  display: flex;
  justify-content: space-between;
  gap: 6px;
  position: relative;
  & > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    .CardHeader__title {
      font-size: $font-size-06;
    }
  }
  .DataVisual__dropdowns-wrapper {
    position: absolute;
    top: 60px;
    left: 0;
  }
  .VisualsListContainer__dropdowns-wrapper {
    position: absolute;
    top: 50px;
    left: $spacing-03;
  }
}
.tree-node {
  display: inline-block;
  min-width: 430px;
  font-family: 'Inter', sans-serif;
  border: 2px solid theme.get-var('border-strong');
  border-radius: $border-radius-01;
  height: 100%;
  cursor: move;
  .node-container {
    background-color: theme.get-var('white');
    height: 100%;
    text-align: left;
    .node-interior {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .node-headline {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        flex-direction: column;
        .node-title-label {
          color: theme.get-var('text-primary');
          padding: $spacing-05 $spacing-05 0 $spacing-05;
          display: grid;
          grid-template-columns: 1fr 24px;
          gap: 16px;
          align-items: center;
          @include heading-07;
          .node-benchmark-indicator {
            height: 20px;
            width: 20px;
            border-radius: $border-radius-02;
            align-self: flex-start;
            margin: $spacing-03 $spacing-05 0 0;
          }
        }
        .node-title-value {
          color: theme.get-var('text-secondary');
          @include heading-08;
          font-weight: $font-weight-06;
          padding-right: $spacing-05;
        }
      }
      .node-body {
        display: flex;
        padding: $spacing-05 $spacing-06 $spacing-05 $spacing-05;
        font-size: $font-size-11;
        font-weight: $font-weight-06;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        border-radius: $border-radius-01;
      }
    }
  }
}
.fullscreen-true,
.exporting {
  .hide-in-export {
    display: none;
  }
  .chart-export-wrapper {
    padding: $spacing-04;
  }
}

.table-export-overflow-menu {
  .cds--overflow-menu-options {
    &__btn {
      max-width: 100%;
    }
  }
}
.getVisual__no-data-wrapper {
  padding: $spacing-03;
}
.getVisual__no-data-info {
  text-align: center;
  line-height: 24px;
}

.DataVisual__notification-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $spacing-05;
  position: relative;
  .DataVisual__sku-grid-toast.cds--toast-notification--warning {
    border-radius: $border-radius-01;
    position: absolute;
    right: 0;
    top: -50px;
    animation: fade-in 1s;
    z-index: 1;
    width: fit-content;
    align-items: center;
    border-left: 3px solid theme.get-var('main-button-danger-primary');
    background: #fff1f1;
    .cds--toast-notification__details {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .cds--actionable-notification__details {
      align-items: center;
    }
    .cds--toast-notification__title,
    .cds--toast-notification__subtitle {
      margin: 0;
    }
    .cds--inline-notification__icon {
      margin-top: 0;
      fill: theme.get-var('main-button-danger-primary');
      path[opacity='0'] {
        fill: transparent;
      }
    }
    .cds--btn--secondary {
      color: theme.get-var('main-button-secondary');
      border-color: theme.get-var('main-button-secondary');
      margin-left: $spacing-05;
      &:hover {
        color: theme.get-var('main-button-secondary');
        border-color: theme.get-var('main-button-secondary');
        background-color: theme.get-var('background-hover');
      }
    }
    .cds--toast-notification__icon {
      margin-top: 0;
      fill: theme.get-var('main-button-danger-primary');
      path[opacity='0'] {
        fill: transparent;
      }
    }
  }
}

.DataVisual__skeleton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-05;
  .cds--inline-loading {
    width: fit-content;
  }
}

.DataVisual__cards-loader {
  border: 1px solid theme.get-var('border-subtle');
  border-radius: $border-radius-01;
}

.ReportContentNavigationTabs__column {
  margin: $spacing-04 0;
  .ReportContentNavigationTabs__switcher.cds--content-switcher {
    .ReportContentNavigationTabs__tab.cds--content-switcher--selected {
      background-color: transparent;
    }
  }
}

.VisualContainer__container {
  margin: $spacing-05 0;
  box-shadow: $boxShadow-01;
  background-color: theme.get-var('background');
  border-radius: $border-radius-01;
  padding: 20px;
  .cds--tabs {
    .cds--tabs__nav-item {
      max-width: fit-content;
    }
  }
  .cds--tab-content {
    padding: 0;
  }
  .cds--subgrid {
    margin-bottom: 0;
  }
  &.VisualContainer__container--loading {
    margin: 0;
    background-color: transparent;
    box-shadow: none;
    padding: 0 0 0 $spacing-05;
  }
  .VisualsTabsContainer__tab-list {
    padding: $spacing-05 0 $spacing-06;
    max-height: none;
  }
  .VisualsTabsContainer__tab-panel {
    .cds--css-grid-column {
      margin: 0;
    }
    .visual-dropdowns {
      padding-top: 0;
    }
  }
  .VisualsListContainer__shared-dropdowns .visual-dropdowns.shared {
    z-index: 3;
  }
}
