@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/colors' as carbonColor;
@use '@carbon/styles/scss/spacing' as *;
@import '../base/typography';

.card {
  position: relative;
  box-shadow: $boxShadow-01;
  border-radius: $spacing-03;
  padding: $spacing-05;
  min-height: 100px;
  background-color: theme.get-var('background');
  border: 1px solid theme.get-var('border-subtle');
  &.card--positive {
    border-left: 4px solid carbonColor.$green-50;
  }
  &.card--negative {
    border-left: 4px solid carbonColor.$red-50;
  }
  &.card--neutral {
    border-left: 4px solid carbonColor.$blue-50;
  }
  .card--home-info {
    display: flex;
    align-items: center;
    row-gap: $spacing-03;
    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .card--home-info__description {
      color: theme.get-var('text-primary');
      margin-right: $spacing-06;
    }
    .card--home-info__value {
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: $spacing-06;
      color: theme.get-var('text-secondary');
      @include heading-05;
    }
  }
  .CardHeader__container {
    margin-bottom: $spacing-05;
  }
}

.CardHeader__container {
  display: flex;
  gap: $spacing-03;
  align-items: center;
  .CardHeader__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: theme.get-var('layer-02');
    padding: $spacing-02;
    border-radius: $border-radius-01;
    color: theme.get-var('icon-primary-bright');
  }
  .CardHeader__title {
    @include heading-02;
  }
}

.ReportMetricsCard__container.card--data {
  grid-template-columns: repeat(20, 1fr);

  .card-item--container {
    border-left: 1px solid theme.get-var('divider');
    margin: 0;
    padding: 0 $spacing-05 0 15%;
    &:first-of-type {
      padding-left: 0;
      border-left: none;
    }
  }
}

.ReportMetricsCard__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: $spacing-05;
  padding-top: 30px;
  margin: 0;
  &.no-divider {
    border-top: none;
  }
  &.with-divider {
    border-top: 1px solid theme.get-var('divider');
  }
  &.with-slider {
    margin-bottom: 36px;
    padding-bottom: $spacing-05;
  }
  .ReportMetricsCard__card-item {
    position: relative;
    padding-left: $spacing-05;
    min-height: 78px;
    &:not(:first-of-type)::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: theme.get-var('divider');
      left: 0;
    }
    display: flex;
    flex-direction: column;
    gap: $spacing-05;
    &:last-of-type {
      padding-right: $spacing-06;
    }
    &:first-of-type {
      padding-left: 0;
    }
    @media (max-width: 1200px) {
      row-gap: $spacing-03;
    }
  }
  .slick-list {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
  .slick-active {
    &:last-of-type .ReportMetricsCard__card-item {
      padding-right: $spacing-06;
    }
    &:first-of-type .ReportMetricsCard__card-item {
      padding-left: 0;
    }
    &:not(:nth-child(5n + 1)) .ReportMetricsCard__card-item {
      padding-left: $spacing-05;
      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: theme.get-var('divider');
        left: 0;
      }
    }
  }
  .slick-dots {
    bottom: -53px;
    li {
      margin: 0 $spacing-04;
    }
    li button:before {
      font-size: 9px;
      color: theme.get-var('toggle-off');
      opacity: 1;
    }
    li.slick-active button:before {
      color: theme.get-var('table-header-active');
    }
  }
  .slick-prev,
  .slick-next {
    color: theme.get-var('icon-neutral');
    bottom: -53px;
    top: auto;
    transform: none;
    z-index: 1;
    &::before {
      content: '';
    }
    &:hover {
      color: theme.get-var('icon-neutral');
    }
    &.slick-disabled {
      opacity: 0.25;
      cursor: default;
    }
  }
  .slick-next {
    right: 0;
  }
  .slick-prev {
    left: 0;
  }
}

.action-card {
  &--tooltip {
    width: 100%;
  }
  &--title {
    @include heading-02;
    padding: $spacing-07;
  }
  &--button {
    margin-top: $spacing-06;
    cursor: pointer;
    background-color: theme.get-var('background-primary');
    color: theme.get-var('text-on-color');
    width: 100%;
    border-radius: $spacing-03;
    padding: $spacing-06;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: $boxShadow-01;
    &:hover {
      background: theme.get-var('main-button-primary-hover');
    }
  }
}
