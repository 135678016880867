@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/mixins';
@import '../abstracts/variables';
@import '../components/CustomGroups.scss';

.AttributeManager {
  &__Header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: $spacing-05;
  }
  &__Notification {
    margin-bottom: $spacing-05;
  }
  &__Actions {
    display: flex;
    gap: $spacing-05;
  }
}

.AttributePlaceholder {
  margin: 8rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-06;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-03;
  }
}

.CreateAttributeModal {
  &__title {
    @include body-01;
    color: theme.get-var('text-primary');
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: $spacing-04;
    margin-top: $spacing-03;
    padding: 0 $spacing-05 $spacing-05 $spacing-05;
  }

  &__request-notification.cds--toast-notification {
    width: 100%;
  }

  .cds--modal {
    &-container {
      border-radius: $border-radius-01;
    }

    &-header {
      .cds--modal-close {
        display: none;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: $spacing-05;
      padding: $spacing-03 $spacing-05;
    }
  }
}

.AttributeList {
  padding: 24px;
  border-radius: $border-radius-01;
  background-color: theme.get-var('background');
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: $spacing-07;

  &__actions-cell {
    white-space: nowrap;
    display: flex;
    gap: $spacing-02;
  }

  .data-table-container {
    border: 1px solid theme.get-var('border-subtle');
    border-bottom: none;
    border-radius: $border-radius-01;
  }

  .cds--pagination {
    border: 1px solid theme.get-var('border-subtle');
    border-top: 0;
    border-radius: 0 0 $border-radius-01 $border-radius-01;
  }
}

.AttributeCell {
  display: flex;
  align-items: center;

  &--error {
    .AttributeCell__Tag {
      @include body-02;
      background-color: theme.get-var('background-error');
      color: theme.get-var('text-error');
    }
  }
}

.AttributeForm {
  display: flex;
  align-items: center;
  gap: 6px;
}

.AttributeForm__stepper {
  .FormStepper .cds--progress {
    max-width: 70%;
  }
  .FormStepper__action-container {
    justify-content: flex-end;
    gap: 16px;
  }
}

.AttributeUpload__container {
  @extend .CustomGroupsUploadValidator__upload-container;
}
.AttributeUpload__container-info {
  @extend .CustomGroupsUploadValidator__upload-info;

  &__description {
    @include body-02;
    line-height: 24px;
  }

  &__list {
    list-style-type: disc;
    margin-left: $spacing-05;
  }
}
.AttributeUpload__preview-table {
  @extend .CustomGroupsUploadValidator__upload-preview-table;
}

.AttributeValidationGrid {
  @extend .CustomGroupsUploadValidator__upload-container;
  min-height: fit-content;
  border-radius: $border-radius-02;
  padding: $spacing-05;
  max-height: calc(100vh - 260px);
  overflow: auto;
  .cds--pagination {
    bottom: -16px;
  }
  .AttributeValidationGrid__header-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .AttributeValidationGrid__warning-filled-icon {
    fill: #a2191f;
  }
  .AttributeValidationGrid__notification-wrapper {
    border-left: 2px solid theme.get-var('notification-error-border');
    border-radius: $border-radius-01;
    width: fit-content;
    margin-bottom: $spacing-05;
    background-color: theme.get-var('notification-error-background');
    .AttributeValidationGrid__notification {
      display: flex;
      align-items: center;
      gap: 6px;
      color: theme.get-var('text-error');
      padding: $spacing-04;
      @include body-02;
      .AttributeValidationGrid__warning-icon {
        color: #a2191f;
      }
    }
  }
  .AttributeValidationGrid__invalid-cell {
    color: theme.get-var('text-error');
  }
}

.AttributeReview {
  .AttributeReview__accordion-wrapper {
    @extend .CustomGroupsUploadValidator__upload-container;
    padding: $spacing-05;
    min-height: fit-content;
    max-height: 350px;
    border: 1px solid theme.get-var('border-subtle');
    .AttributeReview__attribute {
      @include body-emphasis-02;
    }
    .AttributeReview__attribute-sku {
      @include body-02;
      padding-left: $spacing-03;
    }
    .AttributeReview__value {
      @include body-02;
      padding: $spacing-03 0;
      color: theme.get-var('text-secondary');
    }
  }
}
.AttributeUploadSuccess {
  @extend .CustomGroupsUploadSuccess;
  .AttributeUploadSuccess__title {
    @extend .CustomGroupsUploadSuccess__success-title;
  }
  .AttributeUploadSuccess__text {
    @include body-01;
    margin: $spacing-05 0;
  }
  .AttributeUploadSuccess__actions {
    @include body-01;
    margin: $spacing-05 0;
  }
}
