@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables.scss';

.cds--body--with-modal-open .cds--modal {
  .item-order {
    .cds--tooltip {
      z-index: 1;
    }
  }
}

.item-order {
  display: grid;
  grid-template-columns: 1fr 32px 1fr;
  overflow: hidden;
  gap: $spacing-03;
  height: 100%;
  .item-order__column {
    overflow-y: hidden;
  }
  .item-order__droppable-list {
    border-radius: $border-radius-01;
    border: 1px solid theme.get-var('border-subtle');
    .item-order__droppable-column {
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      .item-order__drop-placeholder-container {
        position: relative;
        background: theme.get-var('layer-accent-03');
        .item-order__drop-placeholder {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          border-radius: $border-radius-01;
          text-align: center;
          border: 1px solid theme.get-var('border-interactive-primary');
          background: theme.get-var('layer-accent-03');
          padding: $spacing-06;
          margin: $spacing-05;
        }
      }
    }
  }
  .item-order__item-search {
    margin: $spacing-05 0;
  }
}

.draggable-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-05;
  background: theme.get-var('background');
  border-bottom: 0.5px solid theme.get-var('border-subtle');
  border-radius: $border-radius-01;
  > svg {
    fill: theme.get-var('icon-secondary');
  }
  &.is-dragging {
    border: 1px solid theme.get-var('background-primary');
    > svg {
      fill: theme.get-var('text-primary');
    }
  }
}
