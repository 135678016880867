@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';

.maintenance-page {
  margin: $spacing-13 $spacing-10;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .maintenance-page__header {
    color: theme.get-var('text-primary');
    padding-right: $spacing-03;
    &--wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .maintenance-page__subheader {
    margin-bottom: $spacing-05;
    max-width: 70vw;
  }
  .maintenance-page__counter {
    margin-bottom: $spacing-06;
  }
}
