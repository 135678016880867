//Primary colors
$primary-70: #fcfdff;
$primary-80: #fafdff;
$primary-90: #e1f0ff;
$primary-100: #cde2f9;
$primary-200: #a0c7f3;
$primary-300: #6ea9ed;
$primary-400: #418ee7;
$primary-500: #1b71d5;
$primary-600: #165cac;
$primary-700: #10447f;
$primary-800: #00215d;
$primary-900: #051629;
$primary-950: #030c17;

//Secondary colour
$secondary-100: #fff0eb;
$secondary-500: #f25829;
$secondary-600: #c3370c;

// Gray
$main-gray-5: #f4f3f6;
$main-gray-10: #e5e5eb;
$main-gray-20: #cfceda;
$main-gray-30: #b5b4c6;
$main-gray-40: #9b9ab2;
$main-gray-50: #5c6784;
$main-gray-60: #656382;
$main-gray-70: #4d4b62;
$main-gray-80: #343343;
$main-gray-90: #191820;
$main-gray-100: #08090c;

//Neutral
$black: #0a0909;
$white: #ffffff;

//Primary hover
$primary-hover-60: #f0f5f8;
$primary-hover-70: #f1faff;
$primary-hover-80: #cde2f9;
$primary-hover-90: #a0c7f3;
$primary-hover-100: #75cfff;
$primary-hover-200: #42bdff;
$primary-hover-300: #14adff;
$primary-hover-400: #0092e0;
$primary-hover-500: #0071ad;
$primary-hover-600: #165cac;
$primary-hover-700: #21698f;
$primary-hover-800: #10447f;
$primary-hover-900: #00215d;
$primary-hover-950: #165cac;

//Secondary hover
$secondary-hover-100: #ffe5d0;
$secondary-hover-500: #da732a;
$secondary-hover-600: #942a0a;

//Gray hover
$main-gray-hover-3: #faf9fc;
$main-gray-hover-5: #f4f3f6;
$main-gray-hover-10: #e5e5eb;
$main-gray-hover-20: #c2c1ce;
$main-gray-hover-30: #acabb8;
$main-gray-hover-40: #89889d;
$main-gray-hover-50: #535d77;
$main-gray-hover-60: #515068;
$main-gray-hover-70: #6c698b;
$main-gray-hover-80: #514f68;
$main-gray-hover-90: #413e52;
$main-gray-hover-100: #514b63;

//Neutral
$black-100-hover: #212121;
