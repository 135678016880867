@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';

.sidebar-oneviu-logo {
  margin: 7px 14px;
  height: 32px;

  background-repeat: no-repeat;
  background-image: url('../../assets/icons/oneviu-logo.svg');
}
.sidebar-oneviu-logo--full {
  margin: 7px 14px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/oneviu-logo-full.svg');
}

.sidebar-spacer {
  flex-grow: 1;
}

.sidebar-version {
  border-radius: $border-radius-01;
  background-color: theme.get-var('background-hover');
}

.sidebar-divider {
  border-bottom: 1px solid theme.get-var('divider');
  margin: $spacing-03 $spacing-03;
}

.sidebar-support-section {
  .cds--side-nav__item {
    margin: 0 $spacing-03;
    border-radius: $border-radius-01;
    &:last-child {
      margin-bottom: $spacing-05;
    }
  }
}
