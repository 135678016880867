@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/variables';
@import '../abstracts/mixins';

.NoDataPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $spacing-10 0;
  > svg {
    color: theme.get-var('icon-secondary-dark');
  }
  .NoDataPlaceholder__title {
    margin-top: $spacing-04;
    @include heading-03;
    color: theme.get-var('text-primary');
  }
  .NoDataPlaceholder__description {
    color: theme.get-var('text-secondary');
    margin: 2rem 0;
    @include body-02;
    ul {
      @include body-02;
      list-style: disc;
      list-style-position: inside;
      li {
        margin: $spacing-04 0;
      }
    }
    margin-bottom: $spacing-07;
  }
  .cds--inline-notification {
    max-width: fit-content;
    margin-bottom: $spacing-07;
  }

  .cds--inline-notification__details {
    color: theme.get-var('text-secondary');
    .cds--inline-notification__title {
      @include body-02;
    }
  }

  .cds--btn {
    gap: $spacing-03;
  }
}
