@use './themes/themes.scss' as *;
@use './base/colors' as *;

@use '@carbon/react/scss/theme' with (
  $theme: $ONEVIU
);

@use '@carbon/react';

@use './../styles/themes/overrides';
@use './../styles/abstracts/mixins';
@use './../styles/abstracts/variables';
@use './../styles/base/base';
@use './../styles/base/fonts';
@use './../styles/base/typography';
@use './../styles/layout/layout.scss';

:root {
  @include theme.theme();
}
