@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/_variables';
@import '../abstracts/mixins';
@import '../base/colors';

.IconTitle {
  display: flex;
  margin-bottom: $spacing-05;
  align-items: center;
  &--01 {
    @include heading-01;
  }
  &--02 {
    @include heading-02;
  }
  &--03 {
    @include heading-03;
  }
  &--04 {
    @include heading-04;
  }
  &--05 {
    @include heading-05;
  }
  &--body-02 {
    @include body-02;
  }
  &.IconTitle--secondary {
    background-color: theme.get-var('highlight');
    padding: $spacing-03 $spacing-05;
    border-radius: $spacing-03;
    align-items: center;
    &.IconTitle--faded {
      background-color: #dcdefb4d;
    }
  }
  > .IconTitle__icon {
    height: 20px;
    align-self: center;
    display: flex;
    align-items: center;
    &--highlighted {
      background: $primary-90;
      border-radius: $border-radius-01;
      padding: $spacing-01;
      display: flex;
      align-items: center;
      color: $primary-500;
    }
    &.IconTitle--with-description {
      align-self: flex-start;
    }
  }

  .IconTitle__content {
    margin-left: $spacing-03;

    .IconTitle__title {
      display: flex;
      align-items: center;
      gap: $spacing-03;
    }

    .IconTitle__tooltip {
      color: theme.get-var('icon-primary-bright');
    }

    .IconTitle__description {
      color: theme.get-var('text-helper');
      margin-top: $spacing-03;
      padding-bottom: $spacing-03;
      @include body-02;
    }
  }
}
