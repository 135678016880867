@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@import '../abstracts/mixins';
@import '../abstracts/variables';

//List Box
.cds--list-box {
  border-bottom: none;
  &__field {
    button {
      svg {
        display: none;
      }
    }
    &:focus-within {
      button {
        svg {
          display: block;
        }
      }
    }
  }
  &__menu {
    background-color: theme.get-var('background');
  }
  &__label {
    @include body-03;
    color: theme.get-var('text-primary');
  }
  &__menu-item {
    @include body-03;
    color: theme.get-var('text-primary');
  }
}

//Table
.data-grid-scrollbar-container {
  width: 100%;
  position: sticky;
  bottom: 40px;
  overflow-x: auto;
  z-index: 2;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &_dendrogram {
    bottom: 5px;
  }

  div {
    height: 1px;
    width: 100%;
  }
}

.data-table-container {
  .cds--data-table-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
      height: 0;
    }
  }
}

.dendrogram-table-container .cds--data-table-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    height: 0;
  }
}

.cds--table-sort {
  background-color: theme.get-var('table-header-main');
  color: theme.get-var('text-primary');
  &__flex {
    width: 16px;
    height: 16px;
  }
}

.cds--table-sort.cds--table-sort--active {
  background: theme.get-var('table-header-active');
}
.cds--table-sort:hover {
  background: theme.get-var('table-header-hover');
}
.cds--table-sort__icon,
.cds--table-sort__icon-unsorted {
  fill: theme.get-var('text-primary');
  width: 16px;
  height: 16px;
}

.cds--table-header {
  height: 100%;
  margin: $spacing-03 0;
  &-label {
    @include body-emphasis-02;
    color: theme.get-var('text-primary');
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.cds--batch-actions {
  border-radius: $border-radius-01 $border-radius-01 0 0;
  background-color: theme.get-var('table-header-main');
  border-bottom: 1px solid theme.get-var('border-tile');
}

.cds--data-table {
  height: 100%;
  > thead {
    > tr {
      background-color: theme.get-var('table-header-main');
      > th {
        height: 48px;
        background-color: theme.get-var('table-header-main');
        color: white;
        .cds--table-sort__flex,
        &:last-of-type {
          position: relative;
        }
      }
      &.sticky-header {
        border-radius: $border-radius-01 $border-radius-01 0 0;
        height: 48px;
        z-index: 3;
        position: fixed;
        top: 50px;
        overflow-x: hidden;
      }
      &.visible-header {
        position: inherit;
      }
    }
  }
  tbody {
    & > tr {
      .table-cell {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 $spacing-05;

        &-icon {
          position: relative;
          top: 1px;
          margin-right: $spacing-03;
        }
      }
      &.unselected {
        &:hover {
          & > td {
            cursor: pointer;
          }
        }
      }
    }
  }
  tr,
  td,
  th {
    background: theme.get-var('layer');
  }
  td.table-overflow-menu {
    justify-content: flex-end;
    padding: 0px;
  }
  &-container {
    padding-top: 0;
  }
  & th,
  & td {
    &.cds--table-expand + .cds--table-column-checkbox {
      padding-right: $spacing-05;
      padding-left: $spacing-04;
    }
  }
}

.cds--data-table th.cds--table-column-checkbox {
  background-color: theme.get-var('table-header-main');
  color: theme.get-var('background');
}

.cds--data-table thead th.cds--table-column-checkbox,
.cds--data-table tbody td.cds--table-column-checkbox {
  padding-right: $spacing-05;
  padding-left: $spacing-04;
}

.cds--pagination {
  background: theme.get-var('layer');
  bottom: 0;
  position: sticky;
  z-index: 1;
}

//Dropdown
.cds--list-box--expanded .cds--list-box__menu {
  z-index: 3;
  border-radius: 0 0 $border-radius-01 $border-radius-01;
}

.table-cell {
  .cds--dropdown__wrapper.cds--list-box__wrapper.cds--dropdown__wrapper--inline.cds--list-box__wrapper--inline {
    width: 100%;
  }
  .cds--dropdown__wrapper {
    .cds--dropdown {
      min-width: 0;
      width: 100%;
    }
  }
}

.cds--dropdown__wrapper {
  margin: 0 $spacing-03;
  .cds--dropdown {
    border: 1px solid theme.get-var('border-subtle');
    border-radius: $border-radius-01;
    min-width: 160px;
    max-width: 500px;
    background: theme.get-var('background');

    .cds--list-box__label {
      @include body-02;
      color: theme.get-var('text-secondary');
    }

    .cds--list-box__menu-icon > svg {
      fill: theme.get-var('icon-neutral');
    }

    &--disabled {
      .cds--list-box {
        &__label {
          color: theme.get-var('text-disabled');
        }

        &__menu-icon {
          svg {
            fill: theme.get-var('text-disabled');
          }
        }
      }
    }
  }

  .cds--list-box__menu {
    width: fit-content;
    min-width: 100%;
    max-width: 500px;
    border-radius: 0 0 $border-radius-01 $border-radius-01;
  }
}

// Combo box
.cds--combo-box {
  background: theme.get-var('background');
  .cds--list-box__menu-icon {
    background-image: url('../../assets/icons/Chevron--down.svg');
    background-repeat: no-repeat;
    background-position: center;
    svg {
      display: none;
    }
  }
}
//Buttons
.cds--btn {
  border-radius: $border-radius-01;
  &.cds--btn--primary {
    background-color: theme.get-var('main-button-primary');
    &:not(.cds--btn--icon-only) {
      padding: $spacing-03 $spacing-05;
    }
    &.has-icon {
      padding: $spacing-03 $spacing-08 $spacing-03 $spacing-05;
    }
    &:hover {
      background: theme.get-var('main-button-primary-hover');
    }
    &:active {
      background: theme.get-var('main-button-primary');
    }
  }
  &.cds--btn--secondary {
    padding: $spacing-03 $spacing-05;
    color: theme.get-var('text-button-primary');
    border-color: theme.get-var('border-interactive-primary');
    background-color: transparent;
    &.has-icon {
      padding: $spacing-03 $spacing-08 $spacing-03 $spacing-05;
    }
    &:hover {
      background: theme.get-var('background-active');
    }
    &:active {
      background: transparent;
    }
    &:disabled {
      color: theme.get-var('text-on-color-disabled');
      fill: theme.get-var('text-on-color-disabled');
      border-color: theme.get-var('border-disabled');
      &:hover {
        background-color: transparent;
      }
    }

    &__neutral {
      color: theme.get-var('text-button-neutral');
      border-color: theme.get-var('main-button-secondary');

      > svg {
        color: theme.get-var('icon-neutral');
      }

      &:hover {
        background: theme.get-var('main-button-secondary-hover');
      }

      &:active {
        outline: 1px solid theme.get-var('main-button-secondary');
      }

      &:focus {
        box-shadow: none;
      }

      &:disabled {
        > svg {
          color: theme.get-var('text-on-color-disabled');
        }

        &:active {
          outline: 0;
        }
      }
    }
  }
  &.cds--btn--tertiary {
    padding: $spacing-03 $spacing-05;
    background-color: transparent;
    border-width: 0px;
    color: theme.get-var('text-button-secondary');
    &.has-icon {
      padding: $spacing-03 $spacing-08 $spacing-03 $spacing-05;
    }
    &:hover {
      border: none;
      background-color: theme.get-var('main-button-tertiary');
    }
    &:active {
      border-color: theme.get-var('main-button-primary');
      background-color: transparent;
    }
  }
  &.cds--btn--ghost {
    background-color: transparent;
    border-width: 0px;
    color: theme.get-var('text-button-neutral');
    &:hover {
      border: none;
      background-color: theme.get-var('main-button-ghost-neutral-hover');
    }
    &:active {
      border-color: theme.get-var('main-button-primary');
      background-color: transparent;
    }
  }

  &.cds--btn--danger {
    padding: $spacing-03 $spacing-05;
  }
}

//Accordion
.cds--accordion {
  &__title {
    @include body-emphasis-02;
    margin: 0;
    padding-right: 0;
  }
  &__content {
    padding-left: 0;
    padding-right: 0;
  }
  &__arrow {
    margin: 4px 4px 0 0;
  }
  &__item,
  &__item--active {
    border-top: none;
  }
  &__item {
    &:last-child {
      border-bottom: none;
    }
  }
  &__item--active {
    .cds--accordion__content {
      padding: $spacing-04;
    }
  }
  .cds--accordion__heading {
    @include body-emphasis-02;
    border-bottom: 1px solid theme.get-var('border-subtle');
    height: 48px;
    padding: 11px 16px;
    text-transform: capitalize;
  }
}

//Breadcrumbs
.cds--link {
  margin-bottom: $spacing-03;
  color: theme.get-var('text-primary');
  &:hover {
    cursor: pointer;
  }
}
.cds--breadcrumb-item {
  align-items: baseline;
  :not(:last-child) {
    color: theme.get-var('text-secondary');
  }
}
.cds--breadcrumb {
  align-items: baseline;
  .cds--link,
  .cds--breadcrumb-item {
    @include body-04;
  }
}
// Tag
.cds--tag {
  cursor: pointer;
  z-index: 0;
  padding: $spacing-02 $spacing-03;
  border-radius: $border-radius-01;
  // margin: $spacing-02 $spacing-03;
  &:not(:first-child) {
    margin: $spacing-02 $spacing-03;
  }
}

//Modal
.cds--modal {
  &.is-visible {
    .cds--modal-container {
      border-radius: $border-radius-02;
      background: theme.get-var('background');
    }
  }
  .cds--number__control-btn::before,
  .cds--number__control-btn::after {
    background-color: transparent;
  }
}
.cds--modal-container--sm {
  width: 414px;
  border-radius: $border-radius-01;
}

//Overflow Menu
.cds--overflow-menu {
  border-radius: $border-radius-01;
  &:hover {
    background: theme.get-var('main-button-secondary-hover');
  }
  &:focus {
    outline: 1px solid theme.get-var('focus');
  }

  &-options {
    width: fit-content;
    .cds--overflow-menu--divider {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
    .cds--form-item.cds--checkbox-wrapper {
      padding: 0 $spacing-05;
    }
    &::after {
      display: none;
    }
    &--open {
      min-width: 180px;
    }
    &__btn {
      gap: $spacing-05;
      max-width: none;
      &:focus {
        outline: none;
      }
      &:disabled {
        .cds--overflow-menu-options__option-content {
          color: theme.get-var('text-disabled');
        }
      }
      svg {
        fill: theme.get-var('icon-primary');
      }
    }
    &__option {
      border-radius: $border-radius-01;
    }
  }
  &:disabled {
    cursor: not-allowed;
    svg {
      fill: theme.get-var('text-disabled');
    }
  }
}

// Content switcher
.cds--content-switcher {
  width: fit-content;
  display: flex;
  background-color: transparent;
  border: 1px solid theme.get-var('border-subtle');
  border-radius: 100px;
  gap: 1px;
  &-btn {
    background-color: transparent;
    border: none;
    padding: $spacing-04 $spacing-05;
    clip-path: none;
    transition: none;
    width: fit-content;
    &::after {
      transition: none;
    }
    &:first-of-type {
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      border-color: transparent;
      &:disabled {
        border-color: transparent;
      }
    }
    &:last-of-type {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
      border-color: transparent;
      &:disabled {
        border-color: transparent;
      }
    }
    &:focus,
    &:hover {
      border-radius: 100px;
    }
    &.cds--content-switcher--selected {
      border-radius: 100px;
      z-index: unset;
      background-color: theme.get-var('layer-selected-primary');
      border: 1px solid theme.get-var('background-primary');
      color: theme.get-var('brabackgroundnd-primary');
      margin: -1px;
      justify-content: center;
      &::after {
        border-radius: 100px;
        clip-path: none;
        background-color: transparent;
        transition: none;
      }
    }
    .cds--content-switcher__label {
      @include body-02;
      display: flex;
      align-items: center;
      gap: $spacing-03;
      margin: 0 $spacing-05;
    }
  }
}

// Table Toolbar
.cds--toolbar-content {
  .cds--search {
    margin-right: $spacing-03;
    border-radius: $border-radius-01;
    &:hover {
      background-color: theme.get-var('main-button-secondary-hover');
    }
    .cds--search-input {
      background-color: theme.get-var('background-hover');
      padding: 0 0 0 $spacing-08;
    }
    max-width: 360px;
  }
  .cds--toolbar-action:hover:not([disabled]) {
    &:hover {
      background-color: theme.get-var('main-button-secondary-hover');
    }
  }
  .cds--batch-summary {
    background: theme.get-var('table-header-main');
    color: theme.get-var('text-primary');
    .cds--batch-summary__para {
      @include body-emphasis-02;
    }
  }
  .cds--toolbar-action svg {
    fill: theme.get-var('icon-primary');
    &.cds--loading__svg {
      fill: none;
    }
  }
}

.cds--search-magnifier-icon {
  fill: theme.get-var('icon-primary');
}

.cds--checkbox:checked + .cds--checkbox-label::before,
.cds--checkbox:indeterminate + .cds--checkbox-label::before {
  background-color: theme.get-var('icon-primary-bright');
}
.cds--checkbox:checked + .cds--checkbox-label::after,
.cds--checkbox:indeterminate + .cds--checkbox-label::after {
  border-bottom: 2px solid theme.get-var('icon-on-color:');
  border-left: 2px solid theme.get-var('icon-on-color:');
}
// Search
.cds--search {
  .cds--search-input {
    background-color: theme.get-var('background');
    border-radius: $border-radius-02;
    border: 1px solid theme.get-var('border-strong');
    @include body-03;
  }
  .cds--search-close {
    border-radius: 0 $border-radius-01 $border-radius-01 0;
  }
}

// Sidenav
.cds--side-nav {
  border-right: 2px solid theme.get-var('border-subtle');
  box-shadow: $boxShadow-02;
  width: $sidebar-width;
  background-color: theme.get-var('background-side-nav');
  .cds--side-nav__items {
    display: flex;
    flex-direction: column;
    .cds--side-nav__item {
      cursor: pointer;
      border-radius: $border-radius-01;
      margin: $spacing-02 $spacing-03;
      .cds--side-nav__link,
      .cds--side-nav__submenu {
        .cds--side-nav__icon {
          > svg {
            fill: theme.get-var('links-side-nav');
            color: theme.get-var('links-side-nav');
          }
        }
        &.cds--side-nav__link--current {
          background-color: theme.get-var('selected-link-background');
          color: theme.get-var('link-selected');
          .cds--side-nav__link-text {
            @include body-emphasis-02;
            color: theme.get-var('link-selected');
          }
          .cds--side-nav__icon {
            stroke: theme.get-var('link-selected');
            > svg {
              fill: theme.get-var('link-selected');
              color: theme.get-var('link-selected');
            }
          }
          &:hover {
            .cds--side-nav__link-text {
              color: theme.get-var('link-selected');
            }
            .cds--side-nav__icon {
              stroke: theme.get-var('link-selected');
              > svg {
                fill: theme.get-var('link-selected');
              }
            }
          }
        }
        .cds--side-nav__link-text,
        .cds--side-nav__submenu-title {
          @include body-02;
          color: theme.get-var('text-secondary');
        }
        &:hover {
          .cds--side-nav__link-text,
          .cds--side-nav__submenu-title {
            color: theme.get-var('text-secondary ');
          }
          .cds--side-nav__icon {
            stroke: theme.get-var('icon-primary-dark');
            > svg {
              fill: theme.get-var('icon-primary-dark');
            }
          }
        }
      }
    }

    .cds--side-nav__link[aria-current='page']::before,
    .cds--side-nav__link--current::before {
      background-color: theme.get-var('link-selected');
    }
    .cds--side-nav__item.cds--side-nav__item--active .cds--side-nav__submenu {
      background-color: theme.get-var('background-primary');
      color: theme.get-var('text-on-color');
      &:hover {
        background-color: theme.get-var('background-primary');
        .cds--side-nav__submenu-title {
          color: theme.get-var('text-on-color');
        }
        .cds--side-nav__icon {
          stroke: theme.get-var('text-on-color');
          > svg {
            fill: theme.get-var('text-on-color');
          }
        }
      }
    }
    .cds--side-nav__item--icon {
      .cds--side-nav__menu {
        &-item {
          border-radius: 4px;
          overflow: hidden;
          margin: $spacing-03 0;
          .cds--side-nav__link {
            position: relative;
            padding-left: 56px;
            height: 30px;
            &::before {
              background-color: theme.get-var('divider');
            }
          }
        }
      }
      .cds--side-nav__submenu {
        border-radius: $border-radius-01;
        outline: none;
      }
    }
  }

  &:hover {
    .cds--side-nav__item--large {
      height: fit-content;
    }
    &[aria-expanded='false'] {
      &:hover {
        background-color: theme.get-var('background-hover');
      }
      &::before {
        content: none;
      }
    }
    &[aria-expanded='true'] {
      &:hover {
        .cds--side-nav__icon {
          stroke: theme.get-var('text-on-color');
          > svg {
            fill: theme.get-var('text-on-color');
          }
        }
        .cds--side-nav__submenu-title {
          color: theme.get-var('text-on-color');
        }
      }
    }
  }
  .cds--side-nav__item .cds--side-nav__submenu {
    &[aria-expanded='false'] {
      background-color: transparent;
      color: theme.get-var('text-primary');
      &:hover {
        background-color: theme.get-var('background-hover');
      }
      &::before {
        content: none;
      }
    }
  }
}

.cds--side-nav__overlay {
  z-index: 5;
}

// Tooltips
.cds--tooltip-content {
  padding: 6px;
}

// Toggle
.cds--toggle {
  .cds--toggle__switch--checked {
    background-color: theme.get-var('background-primary');
  }
  .cds--toggle__check {
    fill: none;
  }
}

// Datepicker
.flatpickr-calendar .flatpickr-day.selected {
  background-color: theme.get-var('interactive');
}
.flatpickr-day.today {
  color: theme.get-var('interactive');
  @include body-emphasis-02;
}
.flatpickr-day.today::after {
  background-color: theme.get-var('interactive');
}
.cds--date-picker__input {
  font-family: inherit;
}

.cds--date-picker--range,
.cds--date-picker--single {
  gap: $spacing-03;
  .cds--date-picker__input {
    border-radius: $border-radius-01;
    border: 1px solid theme.get-var('border-subtle');
  }
}

//Number input
.cds--number__input-wrapper {
  min-width: 185px;
  max-width: fit-content;
  border: 1px solid theme.get-var('border-subtle');
  border-radius: $border-radius-01;
  input[type='number'] {
    font-family: inherit;
    outline: 0;
    border: none;
    border-radius: $border-radius-01;
  }
}

.cds--number__controls {
  .cds--number__control-btn {
    border: none;
    border-radius: $border-radius-01;
    &::after {
      height: 0;
    }
    > svg {
      fill: theme.get-var('icon-neutral');
    }
  }
}

// Radio button
.cds--radio-button__appearance {
  width: 1rem;
  height: 1rem;
}

.cds--radio-button:checked
  + .cds--radio-button__label
  .cds--radio-button__appearance::before {
  background-color: theme.get-var('icon-primary-bright');
}

.cds--radio-button__label {
  @include body-03;
}

// Multi select
.cds--multi-select__wrapper {
  .cds--multi-select {
    border: 1px solid theme.get-var('border-subtle');
    border-radius: $border-radius-01;
    .cds--list-box__field {
      border-radius: $border-radius-01;
      gap: $spacing-03;
      justify-content: space-between;
      .cds--list-box__label {
        color: theme.get-var('text-placeholder');
        order: -1;
      }
      .selection-list-container {
        display: flex;
        gap: $spacing-03;
        .cds--tag {
          margin: 0;
          padding-left: $spacing-03;
          background-color: theme.get-var('field-02');
          min-width: 40px;
          .cds--tag__close-icon {
            background-color: theme.get-var('background-hover-subtle');
            color: theme.get-var('text-helper');
            svg {
              display: block;
            }
            &:hover {
              background-color: theme.get-var('tag-hover-gray');
            }
          }
        }
        .selected-items-list {
          align-self: flex-end;
        }
      }
    }
    .cds--tag {
      background-color: theme.get-var('background-primary');
      padding: 0 0 0 $spacing-02;
      .cds--tag__close-icon {
        background-color: theme.get-var('background-primary');
      }
    }
    .cds--checkbox-label {
      font-size: 12.8px;
      &[data-contained-checkbox-state='true']::before {
        background-color: theme.get-var('background-primary');
      }
    }
    &:hover {
      background-color: theme.get-var('background');
    }
    .cds--list-box__menu {
      min-width: fit-content;
    }
  }
  &.hidden-selected-items .cds--list-box__field {
    > .cds--tag {
      display: none;
    }
  }
}

.cds--modal .cds--multi-select {
  background-color: theme.get-var('background');
}

// Tabs
.cds--tabs {
  .cds--tabs__nav-item {
    &:focus {
      outline: none;
    }
  }
  .cds--tabs__nav-item--selected {
    border-color: theme.get-var('border-interactive-secondary');
  }
  .cds--tabs__nav-item:hover {
    transition: background-color 0.1s;
    background-color: theme.get-var('background-hover');
    border-color: theme.get-var('border-strong');
    border-radius: $border-radius-01 $border-radius-01 0 0;
  }
  .cds--tabs__nav-item--selected:hover {
    transition: background-color 0.1s;
    background-color: theme.get-var('background-hover');
    border-color: theme.get-var('border-interactive-secondary');
  }
}

.toolbar-buttons .cds--btn--primary::before {
  position: absolute;
  top: 0.9375rem;
  right: 0;
  display: block;
  width: 0.0625rem;
  height: 1rem;
  border: none;
  background-color: theme.get-var('divider');
  content: '';
  opacity: 1;
  transition: opacity 110ms;
}
.cds--btn--primary.cds--batch-summary__cancel::before {
  display: none;
}

//Notification
.cds--inline-notification {
  border-radius: $border-radius-01;
  min-width: 10rem;

  &__title {
    flex-basis: 100%;
  }
  p {
    @include body-02;
  }
}

.cds--inline-notification__details {
  color: theme.get-var('text-secondary');
  .cds--inline-notification__title {
    @include body-emphasis-02;
  }
}

.cds--dropdown .cds--list-box__field {
  border-radius: $border-radius-01;
}

.cds--text-input,
.cds--text-area {
  border: 1px solid theme.get-var('border-subtle');
  border-radius: $border-radius-01;

  &:disabled {
    border-bottom-color: theme.get-var('border-subtle');
  }
}

.cds--inline-notification--low-contrast.cds--inline-notification::before {
  border: none;
}

.cds--subgrid--wide {
  margin-bottom: $spacing-06;
}

.cds--tooltip {
  svg {
    width: 18px;
    height: 18px;
  }
}

.cds--batch-actions {
  border-radius: $border-radius-01 $border-radius-01 0 0;
  background-color: theme.get-var('table-header-main');
  border-bottom: 1px solid theme.get-var('border-tile');
}
.cds--table-toolbar {
  border-radius: $border-radius-01 $border-radius-01 0 0;
  padding: $spacing-04 0;
  gap: $spacing-03;
}
