@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;

.column-order-actions {
  display: flex;
  padding: $spacing-05;
  justify-content: space-between;
  margin-top: $spacing-05;
  border-top: 1px solid theme.get-var('border-subtle');
}
